import { GADataProps } from "@elements/GAData/GAData";
import {
  getAuthorsName,
  getChapter1,
  getContentCat,
  getLevel2,
  getTagsName,
} from "@elements/GAData/GADataHelper";
import { replaceSpacesWithUnderscores } from "@helper/stringTransform";
import { formatDate } from "@helper/useDateFormat";
import { isClientSide } from "@helper/utils";
import { ProcessedArticleData } from "@transformer/useOSResponse";

export function UpdateGADataForArticle(article: ProcessedArticleData) {
  if (isClientSide && window._data) {
    window.history.pushState("article", article.title, article.urlPath);
    const level2 = getLevel2(article);
    const _data = window._data as GADataProps;
    _data.title = replaceSpacesWithUnderscores(article.title);
    _data.level2 = level2;
    _data.chapter1 = getChapter1(article);
    _data.contentcat = getContentCat(article.contentAccess);
    _data.section = level2;
    _data.contenttype = "1";
    _data.author = getAuthorsName(article.authors || []);
    _data.cue_articleid = article.id;
    _data.articleid = article.id;
    _data.author = getAuthorsName(article.authors || []);
    _data.keyword = getTagsName(article.tags || []);
    _data.pubdate = formatDate(article.datetime, "yyyy-MM-dd HH:mm:ss");
    _data.content_publication_date_utc = article.datetime;
    _data.content_update_date_utc = article.dateEdited;
    _data.infinite_scroll = "1";
  }
}
