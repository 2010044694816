import dummyImage from "@assets/images/default/Featured+Stories.jpg";
import BHBasePage from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import { NoSSR } from "@sphtech/web2-core/components";
import {
  CustomContext,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { Image } from "@src/app/components/Elements/ResponsiveImage/Image";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { OSResponse, PartialCueArticleWithCampaign } from "@typings/Opensearch";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";

export function UgcArticlePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<OSResponse<PartialCueArticleWithCampaign>, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message={dataLoaderResponse.payload ?? ""} />;
  }

  const article = dataLoaderResponse.payload.payload.hits.hits[0];
  const context = article._source?.data?.context;

  const mediaDetailsElement = context?.elements.find((e) => e.type === "media");
  const mediaElement = context?.elements.find((e) => {
    return e.id === mediaDetailsElement?.children[0];
  });
  const url = mediaElement?.relation?.fields["original-caas"]?.url;
  const segments = url?.split("/");
  const optimised = segments
    ? [
        ...segments.slice(0, -2),
        "fit-in/1080x720/filters:format(.webp)",
        ...segments.slice(-2),
      ].join("/")
    : dummyImage;

  const section = context?.sections && context.sections[0]?.name;
  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;

  return (
    <BHBasePage
      uniqueSectionName={section}
      pageAdTargetValue={pageAdTargetValue}
    >
      <article className="">
        {context?.elements.map((e, i: number) => {
          if (e.type === "headline") {
            const headline = e.fields.find((f) => f.name === "headline")
              ?.value as string;
            return (
              <Fragment key={i}>
                <Helmet>
                  <title>{`${headline} — Berita Harian`}</title>
                </Helmet>

                {context.sections && <div>{context.sections[0]?.name}</div>}
                <h1>{headline}</h1>
                <div className="">
                  <div>{context.authors?.map((a) => a.name).join(", ")}</div>
                  <div>
                    Published:{" "}
                    <NoSSR>{new Date(context.created).toLocaleString()}</NoSSR>
                  </div>
                </div>
                <Image
                  className=""
                  srcWidth={1080}
                  srcHeight={500}
                  src={optimised}
                  alt="fixme"
                />
                {mediaElement?.fields.length != undefined &&
                mediaElement.fields.length > 0 ? (
                  <div className="">
                    {
                      mediaElement.fields.find((f) => f.name === "caption")
                        ?.value
                    }
                  </div>
                ) : null}
              </Fragment>
            );
          } else if (e.type === "paragraph") {
            return (
              <p key={i}>
                {e.fields.find((f) => f.name === "paragraph")?.value}
              </p>
            );
          } else if (e.type === "slug" || e.type === "standfirst") {
            return null;
          } else if (e.type === "kicker") {
            return (
              <p key={i}>{e.fields.find((f) => f.name === "kicker")?.value}</p>
            );
          } else {
            return (
              <details key={i}>
                <summary>
                  <code>Unimplemented block {e.type}</code>
                </summary>
                <pre style={{ overflow: "auto" }}>
                  {JSON.stringify(e, null, 2)}
                </pre>
              </details>
            );
          }
        })}
      </article>
    </BHBasePage>
  );
}
