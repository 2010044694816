import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function HomeMetaData(section: string): PageMetaDataValues {
  const metaTags: MetaTagsProps = {
    title: "Beritaharian - Berita Terkini, Singapura, Ekoniaga, Dunia & Sukan",
    description:
      "Berita Harian Singapura - Dapatkan berita terkini, laporan eksklusif, analisis mendalam dan kandungan berbilang media mengenai Singapura, Asia dan dunia.",
    slug: "",
  };

  const gaData: GADataProps = {
    level2: section,
    title: "BH_Homepage",
    section: section,
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
