export function removeTrailingSlash(s: string): string {
  return (
    s &&
    (typeof s === "string" && s.endsWith("/")
      ? s.substring(0, s.length - 1)
      : s)
  );
}

export function removeLeadingSlash(s: string): string {
  return s && (typeof s === "string" && s.startsWith("/") ? s.slice(1) : s);
}
