import PodcastGallery from "@components/Gallery/PodcastGallery";
import VideoGallery from "@components/Gallery/VideoGallery";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import TranslationPopover from "@src/app/components/Translation/TranslationPopover";
import WordPuzzle from "@src/app/components/WordPuzzle/WordPuzzle";
import { useGrapeshotData } from "@src/app/hooks/useGrapeshot";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useState } from "react";

import BHArticleBasePage, {
  AdsSettings,
  BHArticleBasePageProps,
} from "../BHArticleBasePage";
import { PayloadResponseType } from "./Article.server";
import ArticleContentCard from "./ArticleContentCard";
import ArticleInfiniteScroll from "./ArticleInfiniteScroll";
import ArticleMetaData from "./ArticleMetaData";

export function ArticlePage() {
  const routeContext: CustomContext<
    TRouteWithRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const [currentArticle, setCurrentArticle] = useState<ProcessedArticleData>();
  const adsSettings: AdsSettings = useGlobalAdSetting();
  const { gsChannels } = useGrapeshotData(
    dataLoaderResponse.type === ResponseType.SUCCESS
      ? (dataLoaderResponse.payload.article.urlPath as string)
      : "",
  );

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  if (dataLoaderResponse.type === ResponseType.SUCCESS) {
    const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
    const mainArticle = dataLoaderResponse.payload.article;
    const uniqueSectionName = mainArticle.uniqueSectionName;
    const latestRegisteredArticle =
      dataLoaderResponse.payload.latestRegisteredArticle;

    const handleNewArticle = (newArticle: ProcessedArticleData) => {
      setCurrentArticle(newArticle);
    };

    const { gaData, metaTags } = ArticleMetaData({
      article: currentArticle || mainArticle,
      isMainArticle: currentArticle ? false : true,
      gsChannels:
        currentArticle && currentArticle.gsChannels
          ? currentArticle.gsChannels
          : gsChannels,
    });

    const basePageProps: BHArticleBasePageProps = {
      uniqueSectionName,
      pageAdTargetValue,
      gaData,
      metaTags,
      suppliedAdsSettings: adsSettings,
      gsChannels,
    };

    return (
      <BHArticleBasePage {...basePageProps}>
        <div id={mainArticle.id} className="article-card">
          <ArticleContentCard
            mainArticle={mainArticle}
            latestRegisteredArticle={latestRegisteredArticle}
            pageAdTargetValue={pageAdTargetValue}
            pageNumber={0}
            isInfiniteScrollArticle={false}
            isOutbrainEnabled={adsSettings.isOutbrainEnabled || false}
            gsChannels={gsChannels}
          />
        </div>
        <VideoGallery />
        <PodcastGallery />
        <ArticleInfiniteScroll
          mainArticle={mainArticle}
          latestRegisteredArticle={latestRegisteredArticle}
          pageAdTargetValue={pageAdTargetValue}
          onNewArticle={handleNewArticle}
          isOutbrainEnabled={adsSettings.isOutbrainEnabled || false}
        />
        <WordPuzzle />
        <TranslationPopover gsChannels={gsChannels} />
      </BHArticleBasePage>
    );
  }
}
