import { UrlMatch } from "@app/data.ts";
import fetchBHApi from "@helper/fetchBHApi";
import fetchCass from "@helper/fetchCaas";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { SearchPageQuery } from "@src/app/caas/SearchPageQueries";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOSResponse";
import { RouteParam } from "@typings/Data";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type {
  OSResponse,
  PartialCueArticleWithCampaign,
} from "@typings/Opensearch";
import { Match, MatchResult } from "path-to-regexp";

type SearchValueRouteParams = {
  searchValue: string;
};

export type SearchPayloadResponseType = {
  data: ProcessedArticleData[];
  searchedText: string;
};

export type SearchErrorResponseType = {
  message?: string;
  searchedText: string;
};

export const isSearchMatchResult = (
  a: Match<RouteParam>,
): a is MatchResult<SearchValueRouteParams> => {
  return (
    "searchValue" in
    ((a as MatchResult<SearchValueRouteParams>).params as RouteParam)
  );
};

export const fetchSearchPageData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<SearchPayloadResponseType, SearchErrorResponseType>
> => {
  const searchedText = isSearchMatchResult(urlComponent)
    ? urlComponent.params.searchValue
    : "";

  try {
    const data = (await fetchCass({
      searchQuery: SearchPageQuery(searchedText),
    })) as OSResponse<PartialCueArticleWithCampaign>;

    const hits = data.payload.hits.total.value;
    if (hits === 0) {
      return {
        type: ResponseType.CLIENT_ERROR,
        statusCode: 404,
        payload: { searchedText: searchedText },
      };
    }

    const processedData = getOSProcessedArticleData(data, true, 200);

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        data: processedData,
        searchedText: searchedText,
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: {
        message: error.message,
        searchedText: searchedText,
      },
    };
  }
};

export async function fetchMoreSearchedStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "more-search-stories",
    "POST",
    {
      searchedText: requestData.filterArticlesBasedOn,
      sort: requestData.sort,
    },
  );

  return response;
}
