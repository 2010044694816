import { removeLeadingSlash } from "@helper/removeTrailingLeadingSlash";
import { RawImageField } from "@typings/Opensearch";
import { Helmet } from "react-helmet-async";

import {
  ANDROID_PACKAGE,
  APP_NAME,
  FB_APP_ID,
  FB_PAGES,
  IOS_APP_STORE_ID,
  ITUNES_APP,
  OG_SITE_NAME,
  ROBOTS,
  TWITTER_CARD,
  URL_ORIGIN,
} from "./MetaTagsConstants";

export type MetaTagsProps = {
  title: string;
  description?: string;
  slug: string;
  shortLink?: string;
  ogType?: string;
  image?: RawImageField;
  publishedTime?: string;
  modifiedTime?: string;
  keywords?: string[];
  isArticlePage?: boolean;
  addAppleMobileTags?: boolean;
  robotsContent?: string;
  twitterCardContent?: string;
  jsonLd?: object;
  gsChannels?: string[];
};

export default function MetaTags({
  title,
  description,
  slug,
  shortLink,
  ogType,
  image,
  publishedTime,
  modifiedTime,
  keywords = [],
  isArticlePage = false,
  addAppleMobileTags = false,
  robotsContent,
  twitterCardContent,
  jsonLd,
  gsChannels,
}: MetaTagsProps) {
  const canonicalUrl: string =
    URL_ORIGIN + (slug === "home" ? "" : removeLeadingSlash(slug));
  const shortLinkUrl: string = URL_ORIGIN + shortLink;

  return (
    <Helmet>
      <title>{title}</title>
      {/* Search Engine tags */}
      <meta name="robots" content={robotsContent || ROBOTS} />
      <link rel="canonical" href={canonicalUrl} />
      {shortLink && <link rel="shortlink" href={shortLinkUrl} />}
      {description && <meta name="description" content={description} />}
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(", ")} />
      )}

      {/* Open Graph tags */}
      <meta property="og:site_name" content={OG_SITE_NAME} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      {ogType && <meta property="og:type" content={ogType} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image.url} />}
      {image && <meta property="og:image:url" content={image.url} />}
      {image && <meta property="og:image:secure_url" content={image.url} />}
      {image && image.width && (
        <meta property="og:image:width" content={`${image.width}`} />
      )}
      {image && image.height && (
        <meta property="og:image:height" content={`${image.height}`} />
      )}
      {image && <link rel="image_src" href={image.url} />}
      {modifiedTime && (
        <meta property="og:updated_time" content={modifiedTime} />
      )}

      {/* Dcterms tags */}
      {isArticlePage && <meta name="dcterms.title" content={title} />}
      {isArticlePage && <meta name="dcterms.creator" content="cue" />}
      {isArticlePage && description && (
        <meta name="dcterms.description" content={description} />
      )}
      {isArticlePage && <meta name="dcterms.date" content={publishedTime} />}
      {isArticlePage && <meta name="dcterms.type" content="Text" />}
      {isArticlePage && <meta name="dcterms.format" content="text/html" />}
      {isArticlePage && (
        <meta name="dcterms.identifier" content={canonicalUrl} />
      )}

      {/* Article tags */}
      {isArticlePage && (
        <meta property="article:published_time" content={publishedTime} />
      )}
      {isArticlePage && (
        <meta property="article:modified_time" content={modifiedTime} />
      )}

      {/* Twitter tags */}
      <meta name="twitter:card" content={twitterCardContent || TWITTER_CARD} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={canonicalUrl} />
      {description && <meta name="twitter:description" content={description} />}

      {/* App links metadata */}
      <meta property="fb:pages" content={FB_PAGES} />
      <meta property="fb:app_id" content={FB_APP_ID} />

      <meta data-rh="true" property="al:ios:url" content={canonicalUrl} />
      <meta
        data-rh="true"
        property="al:ios:app_store_id"
        content={IOS_APP_STORE_ID}
      />
      <meta data-rh="true" name="apple-itunes-app" content={ITUNES_APP} />
      <meta data-rh="true" property="al:ios:app_name" content={APP_NAME} />
      <meta property="al:android:url" content={canonicalUrl} />
      <meta property="al:android:package" content={ANDROID_PACKAGE} />
      <meta name="google-play-app" content={ANDROID_PACKAGE} />
      <meta property="al:android:app_name" content={APP_NAME} />
      <meta property="al:web:url" content={canonicalUrl} />

      {addAppleMobileTags && (
        <meta name="apple-mobile-web-app-capable" content="yes" />
      )}
      {addAppleMobileTags && (
        <meta name="apple-mobile-web-app-title" content="Berita Harian" />
      )}

      {jsonLd ? (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      ) : null}

      {/* Grapeshot tags */}
      {gsChannels && (
        <meta
          name="grapeshot_tag"
          id="grapeshot"
          data-testid="grapeshot"
          content={gsChannels.join(",")}
        />
      )}
      {gsChannels && (
        <script>{`var gs_channels = ${JSON.stringify(gsChannels)};`}</script>
      )}
    </Helmet>
  );
}
