import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function VideoMetaData(section: string): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: section,
    title: `${capitalize(section)}_Index`,
    section: section,
    contentcat: "1",
    contenttype: "listing",
  };

  const metaTags: MetaTagsProps = {
    title: "Videos, Berita - Beritaharian.sg",
    description:
      "Berita Harian Singapura - Tonton video mengenai Singapura, Asia dan dunia.",
    slug: section,
  };

  return {
    gaData,
    metaTags,
  };
}
