import loadingGif from "@assets/BHPreloader.gif";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { isArrayWithElements } from "@helper/utils";
import { BASE_URL } from "@src/api/constant";
import { getErrorMessage } from "@src/app/helper/getErrorMessage";
import useIntersectionObserver from "@src/app/hooks/useIntersectionObserver";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

interface LoadMoreProps {
  rootClassName?: string;
  onLoadMore: () => Promise<ProcessedArticleData[] | number>;
  hasMore?: boolean;
  loadText?: string;
  articleEventLabel?: boolean;
  landingPage?: string;
}

/**
 * LoadMore component that triggers the onLoadMore function when the user clicks the load more button
 * or when the user scrolls to the bottom of the container.
 *
 * @param rootClassName - Optional class name for styling the root container.
 * @param onLoadMore - The function to load more content.
 * @param hasMore - A boolean indicating if there are more items to load.
 * @param loadText - The text to display on the load more button.
 * @returns A React element that displays the load more button or a loading gif.
 */
export default function LoadMore({
  rootClassName = "",
  onLoadMore,
  hasMore = true,
  loadText = "More Stories",
  articleEventLabel,
  landingPage,
}: LoadMoreProps): React.ReactElement {
  const loadmoreRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const sendDataToGTM = useGTMDispatch();

  const dataToGTMOnInfiniteScroll = useCallback(
    (eventLabelVal: string, landingPageVal: string) => {
      sendDataToGTM({
        event: "infinite_scroll",
        eventCategory: "Infinite Scroll",
        eventAction: "1",
        eventLabel: eventLabelVal,
        landingPage: landingPageVal,
      });
    },
    [sendDataToGTM],
  );

  const handleLoadMore = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await onLoadMore();
      if (
        (isArrayWithElements(response) || response > 0) &&
        typeof window !== "undefined"
      ) {
        let eventLabelVal = window.location.href;
        let landingPageVal = window.location.origin + window.location.pathname;
        if (Array.isArray(response) && response.length > 0) {
          eventLabelVal = articleEventLabel
            ? `${BASE_URL}${response[0].urlPath}`
            : window.location.href;
        }
        if (landingPage) landingPageVal = landingPage;
        dataToGTMOnInfiniteScroll(eventLabelVal, landingPageVal);
      }
    } catch (err: unknown) {
      setError(getErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, [onLoadMore, dataToGTMOnInfiniteScroll, articleEventLabel, landingPage]);

  const handleVisibilityChange = useCallback(
    (element: Element) => {
      if (element === loadmoreRef.current && hasMore) {
        handleLoadMore().catch((err) => {
          console.error(getErrorMessage(err));
        });
      }
    },
    [handleLoadMore, hasMore],
  );

  const { observe, unobserve } = useIntersectionObserver(
    handleVisibilityChange,
    {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    },
  );

  useEffect(() => {
    const element = loadmoreRef.current;
    const onScroll = () => {
      document.removeEventListener("scroll", onScroll);
      if (element) {
        observe(element);
      }
    };

    if (window.innerWidth >= 768) {
      document.addEventListener("scroll", onScroll);
    }

    return () => {
      if (window.innerWidth >= 768) {
        document.removeEventListener("scroll", onScroll);
      }
      if (element) {
        unobserve(element);
      }
    };
  }, [observe, unobserve]);

  const renderButtonText = () => {
    if (error) {
      return error;
    }

    return loadText;
  };

  return (
    <>
      {isLoading ? (
        <LoadingGif />
      ) : (
        <div ref={loadmoreRef} className="col-span-full">
          <div
            className={cx(
              "cursor-pointer border-y-[2px] border-blue-300 py-md text-center md:hidden",
              {
                hidden: !hasMore,
              },
              rootClassName,
            )}
            onClickCapture={() => {
              handleLoadMore().catch((err) => {
                console.error(getErrorMessage(err));
              });
            }}
          >
            <button className="uppercase outline-none">
              {renderButtonText()}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export function LoadingGif() {
  return (
    <div
      data-testid="loading-gif"
      className="flex col-span-full justify-center"
    >
      <img src={loadingGif} alt="Loading..." className="rounded" />
    </div>
  );
}
