import { AdTargetingType, AdUnitProps } from "@typings/Ads.d";
import { useCallback, useEffect, useState } from "react";

import { adSizeMappings, adSlotSizes, adTargetings } from "./AdsConstants";
import { generateAdvertElementId } from "./helpers";

/**
 * This component renders an IMU ad.
 * @param rootClassName - The root class name.
 * @param type - The type of ad unit.
 * @param slot - The ad slot.
 * @param id - The id of the ad.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A IMU ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export const AdUnit = ({
  rootClassName = "",
  type,
  slot = "",
  id = generateAdvertElementId(type),
  pubAdTargeting,
  gsChannels,
}: AdUnitProps) => {
  const [error, setError] = useState<string>("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();
  const adSlotSize = adSlotSizes[type] as googletag.GeneralSize | undefined;
  const adSizeMapping = adSizeMappings[type];
  const adTarget = adTargetings[type];

  /**
   * This function is called when the Google script is loaded.
   * It defines the ad slot and loads the ad.
   * @returns void
   * @see https://developers.google.com/doubleclick-gpt/reference#googletag.cmd.push
   */
  const handleDisplayAd = useCallback(() => {
    try {
      // Make sure that googletag.cmd exists.
      window.googletag =
        (window.googletag as typeof googletag | undefined) || {};
      ((window.googletag as typeof googletag).cmd as googletag.CommandArray) =
        ((window.googletag as typeof googletag).cmd as
          | googletag.CommandArray
          | undefined) || [];
      // Correct: Queueing the callback on the command queue.
      (
        (window.googletag as typeof googletag).cmd as googletag.CommandArray
      ).push(function () {
        // Set page-level targeting.
        pubAdTargeting.forEach((target: AdTargetingType) => {
          googletag.pubads().setTargeting(target.key, target.value);
        });

        // Remove any existing ad slots with the same id.
        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot: googletag.Slot) {
            if (_slot.getSlotElementId()) {
              if (_slot.getSlotElementId() === id) {
                console.log("Destroying Ad slot id -->", id);
                googletag.destroySlots([_slot]);
              }
            }
          });
        // Create the ad slot.
        if (undefined != adSlotSize) {
          const _adSlot = googletag.defineSlot(
            slot,
            adSlotSize as googletag.GeneralSize,
            id,
          ) as googletag.Slot | undefined;

          if (undefined != _adSlot) {
            _adSlot.addService(googletag.pubads());

            if (adSizeMapping) _adSlot.defineSizeMapping(adSizeMapping);

            adTarget?.forEach((target: AdTargetingType) => {
              _adSlot.setTargeting(target.key, target.value);
            });

            // Set gs_channels targeting.
            if (gsChannels) {
              _adSlot.setTargeting("gs_channels", gsChannels.join(","));
            }

            googletag.pubads().enableSingleRequest();
            googletag.enableServices();

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.googletag.slots[type] = _adSlot;
            console.log(
              "googletag slot Added -->",
              type,
              _adSlot.getSlotElementId(),
            );

            // Store the ad slot for later use.
            setAdSlot(_adSlot);
          }
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [
    slot,
    id,
    adSlotSize,
    adSizeMapping,
    pubAdTargeting,
    adTarget,
    type,
    gsChannels,
  ]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log("Ad slot -->", slot, adSlot?.getSlotElementId());
      if (slot && !adSlot) {
        handleDisplayAd();
      }
    }
  }, [slot, handleDisplayAd, adSlot]);

  useEffect(() => {
    /**
     * This function destroys the ad slot when the component is unmounted.
     * @returns void
     * @see https://developers.google.com/doubleclick-gpt/reference#googletag.destroySlots
     */
    return () => {
      const gTag = window.googletag as typeof googletag | undefined;
      if (gTag && gTag.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  return (
    <div className={rootClassName}>
      {!error ? <div data-testid={id} id={id}></div> : error}
    </div>
  );
};
