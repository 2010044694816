import { isClientSide } from "@helper/utils";

/**
 * Calculates the percentage of an element that is visible within the viewport.
 *
 * @param {Element} element - The HTML element.
 * @returns {number | undefined} - The visibility percentage of the element, or undefined if not client-side.
 */
export default function calculateVisibilityPercentage(
  element: Element,
): number | undefined {
  if (!isClientSide) return;

  const rect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight;

  // Calculate visible height of the element within the viewport
  const visibleHeight =
    Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);
  const elementHeight = rect.height;

  // Calculate the percentage of the element that is visible
  const visiblePercentage = (visibleHeight / elementHeight) * 100;

  return visiblePercentage;
}

/**
 * Checks if an element is in the viewport based on a given visibility threshold percentage.
 *
 * @param {Element} element - The HTML element.
 * @param {number} [thresholdPercentage] - The visibility percentage threshold to consider the element in the viewport.
 * @returns {boolean} - True if the element is in the viewport based on the threshold, otherwise false.
 */
export function isElementInViewPort(
  element: Element,
  thresholdPercentage: number,
): boolean {
  const visibilityPercentage = calculateVisibilityPercentage(element);
  if (!visibilityPercentage) return false;

  return visibilityPercentage >= thresholdPercentage;
}
