import SideBlockAds from "@container/SideBlockAds";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import LoadMore from "@elements/LoadMore/LoadMore";
import VideoPlayer from "@elements/Player/VideoPlayer";
import useSwrApi from "@hooks/useSwrApi";
import type { BHBasePageProps } from "@pages/BHBasePage";
import BHBasePage from "@pages/BHBasePage";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import { isArrayWithElements } from "@src/app/helper/utils";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import VideoMetaData from "./VideoMetaData";

export default function Video() {
  const { data } = useSwrApi<LatestVideoResponse[]>("latest-videos");
  const { level1: id } = useParams();

  const [offset, setOffset] = useState(10);
  const [playlistMode, setPlaylistMode] = useState(false);
  const [playlistVideos, setPlaylistVideos] = useState<LatestVideoResponse[]>();
  const [videos, setVideos] = useState<LatestVideoResponse[]>();
  const [playing, setPlaying] = useState<LatestVideoResponse | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      if (!data || data.length === 0) return;
      let nowPlaying = false;
      try {
        let videos: LatestVideoResponse[];
        if (id) {
          videos = await fetchBHApi<LatestVideoResponse[]>(
            `latest-videos/${id}`,
            "GET",
          );
        } else {
          videos = await fetchBHApi<LatestVideoResponse[]>(
            "latest-videos-playlist",
            "GET",
          );
          setPlaylistMode(true);
          setPlaylistVideos(videos);
        }

        setPlaying(videos[0]);
        nowPlaying = id ? false : true;
      } catch (error) {
        nowPlaying = true;
        const playlist = await fetchBHApi<LatestVideoResponse[]>(
          "latest-videos-playlist",
          "GET",
        );
        setPlaylistVideos(playlist);
        setPlaying(playlist[0]);
      }

      const updatedVideos = data.map((video) =>
        video.id === playing?.id
          ? { ...video, nowPlaying: nowPlaying ? true : false }
          : video,
      );
      setVideos(updatedVideos);
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleLoadMoreVideo = async () => {
    const moreVideos = await fetchBHApi<LatestVideoResponse[]>(
      `latest-videos?offset=${offset}`,
      "GET",
    );
    setVideos((prevVideos) => [...(prevVideos || []), ...moreVideos]);
    setOffset((prevSize) => prevSize + 10);

    // This avoids GTM push.
    return 0;
  };

  const handleNowPlaying = useMemo(
    () => (id: string) => {
      setVideos(
        (prevValue) =>
          prevValue &&
          prevValue.map((video) => ({
            ...video,
            nowPlaying: video.id === id,
          })),
      );
    },
    [setVideos],
  );

  const handleOnClickThumbnail = useCallback(
    (props: LatestVideoResponse) => {
      // eslint-disable-next-line react/prop-types
      const { id } = props;
      handleNowPlaying(id);
      setPlaying(props);
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setPlaylistMode(false);
    },

    [handleNowPlaying, setPlaying],
  );

  const sectionName = "videos";
  const { gaData, metaTags } = VideoMetaData(sectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: sectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  const handleOnEndedVideo = () => {
    if (!playlistMode) return;
    if (!playlistVideos) return;
    let index: number = playlistVideos.findIndex(
      (video) => video.id === playing?.id,
    );
    if (index++ === playlistVideos.length - 1) {
      setPlaying(playlistVideos[0]);
    } else {
      setPlaying(playlistVideos[index++]);
    }
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="flex flex-col lg:flex-row">
        <div className="flex lg:px-sm flex-col w-full">
          <span className="font-secondary text-blue-300 text-lg font-semimedium">
            Video
          </span>
          {playing?.id && (
            <VideoPlayer
              playing={playing}
              forwadedRef={containerRef}
              onEnded={handleOnEndedVideo}
              nextPlaylist={handleOnEndedVideo}
            />
          )}
          <div className="w-full border-b border-grey-850 pt-sm">
            <span className="font-secondary text-md lg:text-lg text-grey-400">
              Semua Video
            </span>
          </div>
          {isArrayWithElements(videos) &&
            videos.map((video, index) => (
              <ThumbnailCard<LatestVideoResponse>
                key={index}
                handleOnClickThumbnail={handleOnClickThumbnail}
                variant="video"
                {...video}
              />
            ))}
          <div className="my-md">
            <LoadMore
              onLoadMore={handleLoadMoreVideo}
              loadText="Laporan lain videos"
            />
          </div>
        </div>
        <SideBlockAds
          uniqueSectionName={sectionName}
          adsOnly={true}
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
    </BHBasePage>
  );
}
