import { useEffect, useState } from "react";

import { fetchPuzzleData } from "../server/fetchPuzzleData";

export const usePuzzleData = (puzzleDate: string) => {
  const [questions, setQuestions] = useState<{ [key: string]: string }[]>([]);

  useEffect(() => {
    const loadPuzzleData = () => {
      fetchPuzzleData(puzzleDate)
        .then((data) => {
          if (data && data.length > 0) {
            setQuestions(data);
          }
        })
        .catch(() => {
          console.error(`No data found for date: ${puzzleDate}`);
        });
    };
    loadPuzzleData();
  }, [puzzleDate]);

  return { questions };
};
