import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetingTypeEnum,
  PageAdTargetValue,
} from "@typings/Ads.d";

export const DEFAULT_GOOGLE_SCRIPT_ID = "google_ads_script";
export const DEFAULT_GOOGLE_SCRIPT =
  "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

/**
 * Configuration for Google Publisher Tag (GPT) slot sizes
 * Update new sizes here when needed e.g. midarticlespecial
 * @see https://docs.google.com/spreadsheets/d/1G0vL9aitTVpPSfy18w8EexNCk1lFrSuKbm2g58K8c-E/edit#gid=0
 */
export const adSlotSizes: Record<string, googletag.GeneralSize> = {
  lb1: [[728, 90], [970, 250], ["fluid"]],
  // This is /5908/bh/lb2 ad.
  lb2: [[728, 90], [970, 250], ["fluid"]],
  lb3: [[728, 90], ["fluid"]],
  imu1: [[300, 250], ["fluid"]],
  imu2: [
    [300, 250],
    [300, 600],
  ],
  imu3: [
    [300, 250],
    [300, 600],
  ],
  midarticlespecial: [[1, 1], [300, 250], ["fluid"]],
  prestitial: [1, 1],
  catfish: [1, 1],
  bottomspecial: [
    [320, 50],
    [300, 250],
    [1, 1],
    [300, 50],
    [320, 100],
  ],
};

export const adSizeMappings: Partial<
  Record<AdvertisementTypeEnum, googletag.SizeMappingArray>
> = {
  lb1: [
    [
      [1024, 0],
      [[728, 90], [970, 250], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
  lb3: [
    [
      [1024, 0],
      [[728, 90], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
  lb2: [
    [
      [1024, 0],
      [[728, 90], [970, 250], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
};

export const adTargetings: Partial<
  Record<AdvertisementTypeEnum, AdTargetingType[]>
> = {
  lb1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  lb2: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  lb3: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  imu1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "6" },
  ],
  imu2: [
    { key: "pos", value: "2" },
    { key: "weight", value: "7" },
  ],
  imu3: [
    { key: "pos", value: "3" },
    { key: "weight", value: "8" },
  ],
  midarticlespecial: [
    { key: "pos", value: "midarticlespecial" },
    { key: "weight", value: "5" },
  ],
  prestitial: [
    { key: "pos", value: "prestitial" },
    { key: "weight", value: "3" },
  ],
  catfish: [
    { key: "pos", value: "catfish" },
    { key: "weight", value: "2" },
  ],
  bottomspecial: [{ key: "pos", value: "bottomspecial" }],
};

export const lb1AdSlotSizesInfinteScroll: Record<
  string,
  googletag.GeneralSize
> = {
  desktop: [[728, 90], ["fluid"]],
  mobile: [[320, 50], [320, 100], [320, 250], [300, 250], ["fluid"]],
};

export const midArticleAdSlotSizesInfinteScroll: Record<
  string,
  googletag.GeneralSize
> = {
  midArticle: [
    [1, 1],
    [300, 169],
    [300, 225],
    [480, 270],
    [480, 360],
    [640, 360],
    [640, 480],
    ["fluid"],
  ],
};

export const pubPageAdTargeting: Partial<
  Record<PageAdTargetingTypeEnum, AdTargetingType<PageAdTargetValue>>
> = {
  home: { key: "page", value: PageAdTargetingTypeEnum.HOME },
  listing: { key: "page", value: PageAdTargetingTypeEnum.LISTING },
  article: { key: "page", value: PageAdTargetingTypeEnum.ARTICLE },
  tag: { key: "page", value: PageAdTargetingTypeEnum.TAG },
};
