import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import ArticleCard, {
  ArticleCardElementProps,
  ArticleCardStyleProps,
} from "@elements/Card/Article/ArticleCard";
import type { CreateLoadMoreHandlerProps } from "@elements/LoadMore/CreateLoadMoreHandler";
import { CreateLoadMoreHandler } from "@elements/LoadMore/CreateLoadMoreHandler";
import LoadMore from "@elements/LoadMore/LoadMore";
import { fetchMoreTagsStoriesData } from "@pages/Keyword/Keyword.server";
import useSetFirstRenderedArticleIds from "@src/app/components/Elements/LoadMore/SetFirstRenderedArticleIds";
import { displayAfter } from "@src/app/helper/utils";
import type { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import React, { useState } from "react";

const PAGE_SIZE = 10;
const INITIAL_ARTICLE_COUNT = 10;

type KeywordArticlesListProps = {
  articles: ProcessedArticleData[];
  tagAlias: string;
  tagName: string;
  articleCount: number;
  sectionName: string;
};

export default function KeywordArticlesList({
  articles,
  tagAlias,
  tagName,
  articleCount,
  sectionName,
}: KeywordArticlesListProps) {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean = articles.length == INITIAL_ARTICLE_COUNT;

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(articles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? articles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: tagAlias,
      sort: lastLoadedArticle?.sort,
    };

    const createLoadMoreHandlerProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreTagsStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      lastLoadedArticle,
      loadMoreData,
      renderedArticleIds,
      pageSize: PAGE_SIZE,
      pageNumber,
    };

    const response = await CreateLoadMoreHandler(createLoadMoreHandlerProps);
    return response;
  };

  const articleCardElements: ArticleCardElementProps = {
    hasSectionName: true,
    hasParagraph: true,
    hasDateSideBySide: true,
  };
  const articleCardOverrideStyle: ArticleCardStyleProps = {
    cardWrapper: "flex flex-row gap-4",
    contentWrapper: "gap-2",
    imageStyle: "w-[138px] md:w-[226px] xl:w-[284px]",
    hero: "mb-0",
    description:
      "font-primary text-xs leading-[150%] font-regular mb-sm hidden md:block",
    dateStyle: "md:py-0 text-xxs lg:text-sm",
    sectionNameItem: "lg:justify-start",
    titleStyle: "font-semimedium",
  };

  return (
    <>
      <div className="w-12/12 lg:w-8/12">
        <div className="flex flex-col gap-8">
          <div className="bg-white-200 my-8">
            <div className="p-4 flex items-center flex-col">
              <div className="font-regular italic text-black-200 text-[20px] lg:text-[30px] mb-2">
                {tagName}
              </div>
              <div className="font-regular italic text-grey-400 text-xxs lg:text-md">
                {articleCount} Artikel
              </div>
            </div>
          </div>
          {[...articles, ...loadMoreData].map((context, index) => (
            <React.Fragment key={index}>
              {displayAfter(index, PAGE_SIZE) && (
                <div data-testid={index}>
                  <CompanionAdsLb
                    uniqueSectionName={sectionName}
                    index={index / PAGE_SIZE}
                    pageAdTargetType={PageAdTargetingTypeEnum.TAG}
                  />
                </div>
              )}
              <ArticleCard
                displayWidth={284}
                displayHeight={190}
                elements={articleCardElements}
                overrideStyle={articleCardOverrideStyle}
                {...context}
              />
            </React.Fragment>
          ))}
          {showLoadMore ? (
            <LoadMore
              rootClassName="my-4"
              onLoadMore={handleLoadMore}
              hasMore={hasMoreStories}
              loadText="Lagi Cerita"
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
