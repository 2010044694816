import { ms } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";

const timeZone = "Asia/Singapore";
export const dateFormatWithTz = "yyyy-MM-dd'T'HH:mm:ssXXX";

export function formatDate(
  dateString: string,
  dateFormat?: string,
  setLocale?: boolean,
): string {
  const format = dateFormat ?? "MMM d, yyyy";
  const date = new Date(dateString);
  if (setLocale) {
    return formatInTimeZone(date, timeZone, format, { locale: ms });
  } else {
    return formatInTimeZone(date, timeZone, format);
  }
}

export function formatTime(dateString: string, timeFormat?: string): string {
  const format = timeFormat ?? "h:mm a";
  const date = new Date(dateString);
  return formatInTimeZone(date, timeZone, format);
}

export function formatToUTCString(dateString: string, formatTz?: string) {
  const format = formatTz ?? "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  const date = new Date(dateString);
  return formatInTimeZone(date, "UTC", format);
}

export const getCurrentDate = (formatStr: string = "yyyyMMdd") => {
  const currentDate = new Date();
  return formatInTimeZone(currentDate, timeZone, formatStr);
};
