import GraduationCap from "@assets/wordpuzzle/graduationcap.svg";
import SmillingFaceWithSunGlass from "@assets/wordpuzzle/smiling-face-with-sunglasses.svg";
import SunWithFace from "@assets/wordpuzzle/sun-with-face.svg";
import { twMerge } from "tailwind-merge";

type ResultsProps = {
  correctCount: number;
  totalCount: number;
  wrapperStyle?: string;
  isMobile?: boolean;
};

function Results({
  correctCount,
  totalCount,
  wrapperStyle = "",
  isMobile = false,
}: ResultsProps): JSX.Element {
  let text;
  let image;
  switch (correctCount) {
    case 0:
      text = "Cuba lagi!";
      image = GraduationCap;
      break;
    case 1:
    case 2:
      text = "Bagus!";
      image = SunWithFace;
      break;
    case 3:
      text = "Hebat!";
      image = SmillingFaceWithSunGlass;
      break;
    default:
      text = "Bagus!";
      image = SunWithFace;
      break;
  }

  return (
    <ul
      className={twMerge(
        "flex items-center justify-center basis-6/12",
        wrapperStyle,
      )}
    >
      <li>
        <img src={image} alt={text} />
      </li>
      <li className="mx-2">
        <span className={`${isMobile && "block"}`}>{text}</span> Markah Anda:
        {isMobile && (
          <span className="text-2xl text-sm font-[400] text-blue-400">
            {correctCount} Out Of {totalCount}
          </span>
        )}
      </li>
      {!isMobile && (
        <li className="text-2xl text-sm font-[400] text-blue-400">
          {correctCount} Out Of {totalCount}
        </li>
      )}
    </ul>
  );
}

export default Results;
