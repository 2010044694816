import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import {
  ProcessedAuthorContent,
  ProcessedTagsContent,
} from "@typings/Opensearch";

export function getContentCat(
  contentAccess: LabelCardIconVariety,
): "1" | "2" | "3" {
  switch (contentAccess) {
    case LabelCardIconVariety.FREE:
      return "1";
    case LabelCardIconVariety.PREMIUM:
      return "2";
    case LabelCardIconVariety.REGISTERED_USER:
      return "3";
    default:
      return "1";
  }
}

export function getAuthorsName(authors: ProcessedAuthorContent[]): string {
  return authors.map((author) => author.name).join("|");
}

export function getTagsName(tags: ProcessedTagsContent[]): string {
  return tags.map((tag) => tag.name).join(",");
}

export function getLevel2(article: ProcessedArticleData): string {
  let level2 = article.uniqueSectionName || "";
  if (article.parentSection) {
    level2 = article.parentSection;
  }
  return level2.replace("-", " ").toLowerCase();
}

export function getChapter1(article: ProcessedArticleData): string {
  let subSectionName = "";
  if (article.parentSection) {
    subSectionName = article.sectionName || "";
  }
  return subSectionName;
}
