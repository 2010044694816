import { capitalizeFirstCharOnly } from "@src/app/helper/stringTransform";
import { twMerge } from "tailwind-merge";

type QuestionCardProps = {
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctMalayWord: string;
  onWordClick: (word: string) => void;
  isMobile?: boolean;
};

function QuestionCard({
  englishWord,
  options,
  selectedWord,
  correctMalayWord,
  onWordClick,
  isMobile,
}: QuestionCardProps): JSX.Element {
  return (
    <div
      className={
        isMobile
          ? "flex flex-col"
          : "flex items-center m-0 mb-4  mr-2 relative left-1.5"
      }
    >
      <div className="mb-0">
        <span
          id="staticEnglish_web"
          className="font-secondary text-2xl font-semibold text-blue-400 text-2md"
        >
          {capitalizeFirstCharOnly(englishWord)}
        </span>
        <span className="font-secondary text-2xl font-semibold text-blue-400 text-2md">
          =
        </span>
      </div>
      <div className="m-0">
        <ul
          className={`inline-flex items-start gap-xs p-0 list-none md:ml-xxs  ${isMobile ? "flex-col mt-1 sm:flex-row ml-0" : "flex-row min-w-[420px] lg:min-w-[500px]"}`}
        >
          {options.map((word, index) => (
            <div
              key={index}
              onClick={() => {
                onWordClick(word);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  onWordClick(word);
                }
              }}
              role="button"
              tabIndex={0}
              className={twMerge(
                "font-primary flex justify-center items-center px-2 min-w-[160px] h-[30px] bg-white-100 cursor-pointer text-grey-800 text-md not-italic leading-normal text-nowrap rounded-full",
                selectedWord !== null
                  ? word === selectedWord
                    ? word.toLowerCase() === correctMalayWord
                      ? "border-2 border-green-200"
                      : "border-2 border-red-500"
                    : "border-1 border-grey-550 bg-grey-300"
                  : "",
              )}
            >
              {capitalizeFirstCharOnly(word)}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QuestionCard;
