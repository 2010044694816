import pdfIcon from "@assets/article/pdfIcon.avif";
import Link from "@elements/Typography/Link";
import { Attachment } from "@typings/Opensearch";
import { twMerge } from "tailwind-merge";

type AttachmentCardProps = {
  attachment: Attachment;
  className?: string;
};

export default function AttachmentCard({
  attachment,
  className,
}: AttachmentCardProps) {
  return (
    <div data-testid="attachment-pdf" className={twMerge("flex", className)}>
      <Link
        destination={attachment.content["attachmentLink-caas"]}
        relativePath={false}
        type="application/pdf"
        className="flex gap-1 items-center text-md text-blue-300 hover:underline hover:text-blue-700"
      >
        <img src={pdfIcon} alt="pdf-icon" width={16} height={16} />
        {attachment.content.title}
      </Link>
    </div>
  );
}
