import { GADataProps } from "@elements/GAData/GAData";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getCookieByName, isClientSide } from "@helper/utils";
import { useCallback, useEffect, useState } from "react";

export function useNeuronPageViewPush() {
  const sendDataToGTM = useGTMDispatch();
  const [dataAvailable, setDataAvailable] = useState(false);

  const neuronPageView = useCallback(() => {
    if (!isClientSide) return;
    if (typeof window._data === "undefined") return;

    const permutive_id = window.localStorage.getItem("permutive-id") || "";
    const neuron_dataLayer = window._data as GADataProps;

    const visitorcat = getCookieByName("visitorcat") as unknown;
    const mysphw = getCookieByName("mysphw");
    if (visitorcat !== undefined && mysphw !== undefined) {
      neuron_dataLayer.visitorcat = visitorcat as 1 | 2;
      neuron_dataLayer.at = mysphw;
    }

    const pageViewData = {
      event: "pageview",
      at: neuron_dataLayer.at,
      permutive_id: permutive_id,
      visitorcat: neuron_dataLayer.visitorcat,
      articleid: neuron_dataLayer.articleid,
      cue_articleid: neuron_dataLayer.cue_articleid,
      author: neuron_dataLayer.author || "",
      chapter1: neuron_dataLayer.chapter1,
      chapter2: neuron_dataLayer.chapter2,
      chapter3: "",
      contentcat: neuron_dataLayer.contentcat,
      contenttype: neuron_dataLayer.contenttype,
      keyword: neuron_dataLayer.keyword,
      level2: neuron_dataLayer.level2,
      pubdate: neuron_dataLayer.pubdate,
      content_publication_date_utc:
        neuron_dataLayer.content_publication_date_utc,
      content_update_date_utc: neuron_dataLayer.content_update_date_utc,
      titleGa: neuron_dataLayer.title,
      url: window.location.href,
    };

    sendDataToGTM(pageViewData);
  }, [sendDataToGTM]);

  useEffect(() => {
    if (!isClientSide) return;

    const interval = setInterval(() => {
      if (typeof window._data !== "undefined") {
        setDataAvailable(true);
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (dataAvailable) {
      neuronPageView();
    }
  }, [dataAvailable, neuronPageView]);

  return neuronPageView;
}
