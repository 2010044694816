import CASBlock from "@components/CASBlock/CASBlock";
import { CAS_CDP_PAYWALL_ID } from "@helper/getEnvVariables";
import useCasScript from "@hooks/useCasScript";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";

import { NormalGenGPaywal, NormalPaywall } from "./BannerHelper";
import { ArticleBannerProps } from "./RegistrationBanner";

export default function SubscriptionBanner({
  isInfiniteScrollArticle,
  pageNumber,
  isGenG = false,
}: ArticleBannerProps) {
  const { isScriptReady: isCasScriptReady } = useCasScript();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  const casBlockProps = {
    key: `cas-${CAS_CDP_PAYWALL_ID}`,
    casId: CAS_CDP_PAYWALL_ID,
    ...(isInfiniteScrollArticle && { index: pageNumber }),
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute bg-white-100 bg-opacity-70 w-full h-[120px] bottom-8"></div>
        {isGenG
          ? NormalGenGPaywal(userDisplayName)
          : NormalPaywall(userDisplayName)}
      </div>
      {isCasScriptReady && <CASBlock {...casBlockProps} />}
    </div>
  );
}
