import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

import nextArrow from "@assets/actions/next-arrow.svg";
import prevArrow from "@assets/actions/prev-arrow.svg";
import WebinarCard from "@elements/Card/Webinar/WebinarCard";
import { ProcessedWebinarData } from "@src/app/hooks/transformer/useOSWebinarResponse";
import { useEffect, useRef } from "react";
import Slider, { Settings } from "react-slick";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";

export type CarouselPropType = {
  renderItems: ProcessedWebinarData[];
};

function WebinarCarousel({ renderItems }: CarouselPropType) {
  const SliderComponent =
    // @ts-expect-error @typescript-eslint/no-unsafe-assignment
    typeof window === "undefined" ? Slider.default : Slider;
  const bhCustomEvents = useBHCustomEventAnalyticsCall();
  const sliderRef = useRef<Slider>(null);
  useEffect(() => {
    const dotsContainer = document.querySelector(".slick-dots");

    const handleDotClick = () => {
      bhCustomEvents("webinar", "click", "pagination");
    };

    if (dotsContainer) {
      dotsContainer.addEventListener("click", handleDotClick);
    }

    return () => {
      if (dotsContainer) {
        dotsContainer.removeEventListener("click", handleDotClick);
      }
    };
  }, []);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      bhCustomEvents("webinar", "click", "next");
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      bhCustomEvents("webinar", "click", "previous");
    }
  };

  // Need to do this as workaroudn known issue, https://github.com/akiran/react-slick/issues/1130
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, currentSlide, slideCount, ...others } = props;
    return <span {...others}>{children}</span>;
  };

  const settings: Settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: (
      <SlickButtonFix>
        <button onClick={next} data-testid="next-button">
          <img src={nextArrow} alt="next-arrow" />
        </button>
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <button onClick={previous} data-testid="prev-button">
          <img src={prevArrow} alt="prev-arrow" />
        </button>
      </SlickButtonFix>
    ),
  };

  return (
    <>
      {renderItems.length === 1 ? (
        <WebinarCard webinarData={renderItems[0]} addShadowWrapper={true} />
      ) : (
        <div
          className="px-lg pt-sm pb-lg mb-lg md:px-xxl md:pt-lg md:pb-xl md:mb-xl"
          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
        >
          <SliderComponent ref={sliderRef} {...settings}>
            {renderItems.map((item, index) => (
              <WebinarCard webinarData={item} key={index} />
            ))}
          </SliderComponent>
        </div>
      )}
    </>
  );
}

export default WebinarCarousel;
