import GrinningFace from "@assets/wordpuzzle/grinning-face-with-big-eyes.svg";
import Group from "@assets/wordpuzzle/Group.svg";
import ThinkingFace from "@assets/wordpuzzle/thinking-face.svg";

type StatusMessageProps = {
  status: string;
  incorrectCombination: string;
};

function StatusMessage({
  status,
  incorrectCombination,
}: StatusMessageProps): JSX.Element {
  return (
    <>
      {status === "correct" && (
        <div className="flex items-center shadow-lg justify-center flex-row  w-full md:h-14 bg-white-100">
          <img
            src={GrinningFace}
            className="w-12 h-12 p-2"
            alt="Status Correct"
          />
          <span className="text-sm text-green-500">Betul!</span>
        </div>
      )}
      {status === "correctNext" && (
        <div className="flex items-center shadow-lg justify-center flex-row  w-full md:h-14 bg-white-100">
          <img src={Group} className="p-2 w-12 h-12" alt="Status CorrectNext" />
          <span className="text-sm text-green-500 font-primary">
            Pilih perkataan yang betul
          </span>
        </div>
      )}
      {status === "incorrect" && (
        <div className="flex items-center shadow-lg justify-center flex-row  w-full md:h-14 bg-white-100">
          <img
            src={ThinkingFace}
            className="w-12 h-12 p-2"
            alt="Status Incorrect"
          />
          <span className="text-sm">Salah!</span>
          <span className="pl-2 text-sm text-gray-700">
            {incorrectCombination}
          </span>
        </div>
      )}
    </>
  );
}

export default StatusMessage;
