import SideBlockAds from "@container/SideBlockAds";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import VideoPlayer from "@elements/Player/VideoPlayer";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import { isArrayWithElements } from "@src/app/helper/utils";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { PlaylistType } from "@typings/Playlist.d";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export default function WebinarRecordedVideo() {
  const [playlistMode, setPlaylistMode] = useState(false);
  const [playlistVideos, setPlaylistVideos] = useState<LatestVideoResponse[]>();
  const [videos, setVideos] = useState<LatestVideoResponse[]>();
  const [playing, setPlaying] = useState<LatestVideoResponse | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      let nowPlaying = false;
      try {
        const videos: LatestVideoResponse[] = await fetchBHApi<
          LatestVideoResponse[]
        >(`latest-videos-playlist?playlistId=${PlaylistType.WEBINAR}`, "GET");
        setPlaylistMode(true);
        setPlaylistVideos(videos);

        setPlaying(videos[0]);
        nowPlaying = true;
        const updatedVideos = videos.map((video) =>
          video.id === videos[0].id
            ? { ...video, nowPlaying: nowPlaying ? true : false }
            : video,
        );
        setVideos(updatedVideos);
      } catch (error) {
        console.error("Something went wrong", error);
      }
    };
    void fetchData();
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleNowPlaying = useMemo(
    () => (id: string) => {
      setVideos(
        (prevValue) =>
          prevValue &&
          prevValue.map((video) => ({
            ...video,
            nowPlaying: video.id === id,
          })),
      );
    },
    [setVideos],
  );

  const handleOnClickThumbnail = useCallback(
    (props: LatestVideoResponse) => {
      // eslint-disable-next-line react/prop-types
      const { id } = props;
      handleNowPlaying(id);
      setPlaying(props);
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setPlaylistMode(false);
    },

    [handleNowPlaying, setPlaying],
  );

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const handleOnEndedVideo = () => {
    if (!playlistMode) return;
    if (!playlistVideos) return;
    let index: number = playlistVideos.findIndex(
      (video) => video.id === playing?.id,
    );
    if (index++ === playlistVideos.length - 1) {
      setPlaying(playlistVideos[0]);
    } else {
      setPlaying(playlistVideos[index++]);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex lg:px-sm flex-col w-full">
        <span className="font-secondary text-blue-300 text-lg font-semimedium">
          Webinar Rakaman
        </span>
        {playing?.id && (
          <VideoPlayer
            playing={playing}
            forwadedRef={containerRef}
            onEnded={handleOnEndedVideo}
            nextPlaylist={handleOnEndedVideo}
            showDescription={true}
            variant="webinar"
            cardStyle={{
              nameClass: "text-sm lg:text-[30px]",
              dateClass: "text-xxs lg:text-xs font-semibold text-grey-400",
              wrapperClass:
                "flex-col lg:flex-row items-start gap-2 lg:items-center",
            }}
          />
        )}
        <div className="w-full border-b border-grey-850 pt-sm">
          <span className="font-secondary text-md lg:text-lg text-grey-400">
            Semua Video Webinar
          </span>
        </div>
        {isArrayWithElements(videos) &&
          videos.map((video, index) => (
            <ThumbnailCard<LatestVideoResponse>
              key={index}
              handleOnClickThumbnail={handleOnClickThumbnail}
              variant="webinar"
              {...video}
            />
          ))}
      </div>
      <SideBlockAds
        uniqueSectionName={"webinar"}
        adsOnly={true}
        pageAdTargetingProps={pageAdTargetValue}
        className="mt-[35px]"
      />
    </div>
  );
}
