import { getCookieByName, isClientSide } from "@helper/utils";
import {
  sendPermutiveIdentify,
  SendPermutiveIdentifyProps,
} from "@src/app/helper/sendPermutiveIdentify";
import { useCallback, useEffect, useState } from "react";

/**
 * Custom React Hook: usePermutiveIdentifyUpdateHook
 * Description: Hook to update permutive identify
 * @param props - The props required by the hook (if any)
 * @returns data - The data returned by the hook
 */
export default function useSendPermutiveIdentify() {
  const [permutiveCookies, setPermutiveCookies] =
    useState<SendPermutiveIdentifyProps>({
      ptid: getCookieByName("mysph_lr") || "",
      per_userid: getCookieByName("mysphw") || "",
      per_suid: getCookieByName("suid") || "",
    });
  const [cookiesAvailable, setCookiesAvailable] = useState(false);

  const handleCookieChange = useCallback(() => {
    const new_ptid = getCookieByName("mysph_lr") || "";
    const new_per_userid = getCookieByName("mysphw") || "";
    const new_per_suid = getCookieByName("suid") || "";

    if (
      permutiveCookies.per_userid !== new_per_userid ||
      permutiveCookies.ptid !== new_ptid ||
      permutiveCookies.per_suid !== new_per_suid
    ) {
      setPermutiveCookies({
        ptid: new_ptid,
        per_userid: new_per_userid,
        per_suid: new_per_suid,
      });
    }
  }, [permutiveCookies, setPermutiveCookies]);

  useEffect(() => {
    sendPermutiveIdentify(permutiveCookies);
  }, [permutiveCookies]);

  useEffect(() => {
    if (!isClientSide) return;

    const interval = setInterval(() => {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      if (
        getCookieByName("mysph_lr") !== undefined ||
        getCookieByName("mysphw") !== undefined ||
        getCookieByName("suid") !== undefined ||
        (window._data !== undefined && window._data.visitorcat === 1)
      ) {
        setCookiesAvailable(true);
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (cookiesAvailable) {
      handleCookieChange();
    }
  }, [cookiesAvailable, handleCookieChange]);

  useEffect(() => {
    if ("cookieStore" in window) {
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      window.cookieStore.addEventListener("change", handleCookieChange);

      return () => {
        window.cookieStore.removeEventListener("change", handleCookieChange);
      };
    }
  }, [handleCookieChange]);
}
