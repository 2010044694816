interface PuzzleData {
  [key: string]: { [key: string]: string }[];
}
export const fetchPuzzleData = async (
  formattedDate: string,
): Promise<{ [key: string]: string }[] | null> => {
  try {
    const response = await fetch("/feeds/puzzle");
    const puzzleData: PuzzleData = (await response.json()) as PuzzleData;
    const result = puzzleData[formattedDate];
    return result;
  } catch (error) {
    console.error("Error fetching puzzle data:", error);
    return null;
  }
};
