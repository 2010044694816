import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

import nextArrow from "@assets/actions/next-arrow.svg";
import prevArrow from "@assets/actions/prev-arrow.svg";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import LabelCardIcon from "@components/Icon/LabelCardIcon";
import { ProcessedArticleData } from "@src/app/hooks/transformer/useOSResponse";
import { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { twMerge } from "tailwind-merge";

import ArticleCard from "../Elements/Card/Article/ArticleCard";

export type CarouselPropType = {
  renderItems: ProcessedArticleData[];
  variant?: LabelCardIconVariety;
  renderedCount?: number;
  className?: string;
};

function ArticleCardCarousel({
  renderItems,
  renderedCount = 5,
  className,
  variant = LabelCardIconVariety.REGISTERED_USER,
}: CarouselPropType) {
  const [itemPerSlide, setItemPerSlide] = useState(renderedCount);

  const SliderComponent =
    // @ts-expect-error @typescript-eslint/no-unsafe-assignment
    typeof window === "undefined" ? Slider.default : Slider;

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined" && window.innerWidth <= 768) {
        setItemPerSlide(2);
      } else {
        setItemPerSlide(renderedCount);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [renderedCount]);

  const sliderRef = useRef<Slider>(null);
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Need to do this as workaroudn known issue, https://github.com/akiran/react-slick/issues/1130
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, currentSlide, slideCount, ...others } = props;
    return <span {...others}>{children}</span>;
  };

  const settings: Settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: itemPerSlide,
    slidesToScroll: itemPerSlide,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: (
      <SlickButtonFix>
        <button onClick={next} data-testid="next-button">
          <img src={nextArrow} alt="next-arrow" />
        </button>
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <button onClick={previous} data-testid="prev-button">
          <img src={prevArrow} alt="next-arrow" />
        </button>
      </SlickButtonFix>
    ),
  };

  return (
    <div
      className={twMerge(
        "p-xl shadow-slider border border-grey-100 border-t-0",
        className,
      )}
    >
      <div className="flex items-center justify-between pb-4 w-full">
        <LabelCardIcon contentAccess={variant} />
        <a
          href="/registered?ref=home_widget"
          className="text-sm text-grey-400"
        >{`Laporan Lain >`}</a>
      </div>
      <SliderComponent ref={sliderRef} {...settings}>
        {renderItems.map((item) => {
          return (
            <ArticleCard
              key={item.id}
              displayWidth={155}
              displayHeight={103}
              overrideStyle={{
                cardWrapper:
                  "flex flex-col items-center justify-center w-full px-xs",
                headerWrapper: "block w-full",
                contentWrapper: "w-full",
                dateStyle: "text-xxs text-grey-400",
              }}
              {...item}
            />
          );
        })}
      </SliderComponent>
    </div>
  );
}

export default ArticleCardCarousel;
