import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import ArticleCardCarousel from "@components/Carousel/ArticleCardCarousel";
import ArticleImage from "@components/Carousel/ArticleImage";
import CASBlock from "@components/CASBlock/CASBlock";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import { AdsImu1, AdsMidArticle } from "@elements/Advertisement/variant/AdsImu";
import {
  CompanionAdsImu1,
  CompanionAdsMidArticle,
} from "@elements/Advertisement/variant/CompanionAdImu";
import RelatedArticle from "@elements/RelatedArticle/RelatedArticle";
import Paragraph from "@elements/Typography/Paragraph";
import { CAS_CDP_REGWALL_ID } from "@helper/getEnvVariables";
import { getUserType } from "@helper/mySPH";
import { isArrayWithElements } from "@helper/utils";
import useCasScript from "@hooks/useCasScript";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import hasServiceFlag from "@src/app/helper/hasServiceFlag";
import { type ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import {
  type ProcessedArticleBody,
  type RelatedArticles,
} from "@typings/Opensearch";
import React, { useCallback, useEffect, useState } from "react";

import ApplyParagraphAnnotations from "./ApplyParagraphAnnotations";
import { YOUTUBE_WATCH_URL } from "./Embeds/constants";
import Embed from "./Embeds/Embed";
import YouTubeEmbedCustom from "./Embeds/YouTubeEmbedCustom";
import GetLimitedParagraphsFromArticleBody from "./GetLimitedParagraphsFromArticleBody";
import ParagraphList from "./ParagraphElements/ParagraphList";
import PullQuoteCard from "./ParagraphElements/PullQuoteCard";
import SubHead from "./ParagraphElements/SubHead";
import WrapperContainer from "./ParagraphElements/WrapperContainer";
import RegistrationBanner from "./RegistrationBanner";
import SubscriptionBanner from "./SubscriptionBanner";

export type ArticleParagraphProps = {
  articleId: string;
  contentAccess: LabelCardIconVariety;
  articleBody: ProcessedArticleBody | null;
  uniqueSectionName: string | undefined;
  relatedArticles: RelatedArticles;
  latestRegisteredArticle: ProcessedArticleData[];
  pageNumber: number;
  isInfiniteScrollArticle?: boolean;
  forApi?: boolean;
  gsChannels?: string[];
};

export default function ArticleParagraph({
  articleId,
  contentAccess,
  articleBody,
  uniqueSectionName,
  relatedArticles,
  latestRegisteredArticle,
  isInfiniteScrollArticle = false,
  pageNumber,
  forApi = false,
  gsChannels,
}: ArticleParagraphProps) {
  const [showSubBanner, setShowSubBanner] = useState<boolean>(false);
  const [showGenGSubBanner, setShowGenGSubBanner] = useState<boolean>(false);
  const [showRegBanner, setShowRegBanner] = useState<boolean>(false);
  const [trimmedParagraphs, setTrimmedParagraphs] =
    useState<ProcessedArticleBody | null>(
      !forApi && articleBody
        ? GetLimitedParagraphsFromArticleBody(articleBody, 2)
        : articleBody,
    );
  const [showNormalBanner, setShowNormalBanner] = useState<boolean>(false);
  const { isScriptReady: isCasScriptReady } = useCasScript();

  const fetchFullArticleBody = useCallback(() => {
    fetchBHApi<ProcessedArticleData[]>(`article/${articleId}`, "GET")
      .then((data) => {
        setTrimmedParagraphs(data[0].articleBody);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [articleId]);

  useEffect(() => {
    // Get the user type (e.g., y-sub, y-anoy, etc.)
    const userType = getUserType();
    console.log("user type -->", userType);
    console.log("service flag gen g -->", hasServiceFlag("bh_geng"));

    // Condition 1: If the content requires a PREMIUM subscription,
    // the section is "gen-g", and the user doesn't have the "bh_geng" service flag,
    // show the Gen-G subscription banner and trim the article body to show only one paragraph.
    if (
      contentAccess === LabelCardIconVariety.PREMIUM &&
      uniqueSectionName === "gen-g" &&
      !hasServiceFlag("bh_geng")
    ) {
      setShowGenGSubBanner(true);
      setTrimmedParagraphs(
        articleBody && GetLimitedParagraphsFromArticleBody(articleBody, 1),
      );

      // Condition 2: If the content requires a PREMIUM subscription,
      // but the user is not a "y-sub" type, show the generic subscription banner.
    } else if (
      contentAccess === LabelCardIconVariety.PREMIUM &&
      userType !== "y-sub"
    ) {
      setShowSubBanner(true);

      // Condition 3: If the content is for REGISTERED_USER,
      // and the user is either anonymous ("y-anoy") or the user type is undefined,
      // show the registration banner.
    } else if (
      contentAccess === LabelCardIconVariety.REGISTERED_USER &&
      (userType === "y-anoy" || userType === undefined)
    ) {
      setShowRegBanner(true);

      // Condition 4: For all other cases where the user is not a "y-sub" type,
      // show the normal banner and set the full article body to be displayed.
    } else if (userType !== "y-sub") {
      if (
        contentAccess === LabelCardIconVariety.REGISTERED_USER &&
        !isInfiniteScrollArticle
      ) {
        fetchFullArticleBody();
      } else {
        setTrimmedParagraphs(articleBody);
      }
      setShowNormalBanner(true);

      // Default: If none of the above conditions are met,
      // set the full article body to be displayed and hide all banners.
    } else {
      if (
        (contentAccess === LabelCardIconVariety.PREMIUM ||
          contentAccess === LabelCardIconVariety.REGISTERED_USER) &&
        !isInfiniteScrollArticle
      ) {
        fetchFullArticleBody();
      } else {
        setTrimmedParagraphs(articleBody);
      }
      setShowGenGSubBanner(false);
      setShowSubBanner(false);
      setShowRegBanner(false);
      setShowNormalBanner(false);
    }
  }, [
    contentAccess,
    articleBody,
    uniqueSectionName,
    fetchFullArticleBody,
    isInfiniteScrollArticle,
  ]);

  const casBlockProps = {
    key: `cas-${CAS_CDP_REGWALL_ID}`,
    casId: CAS_CDP_REGWALL_ID,
    ...(isInfiniteScrollArticle && { index: pageNumber }),
  };

  let paragraphCount = 0;
  const pageAdTarget = PageAdTargetingTypeEnum.ARTICLE;

  let adsImu1 = (
    <AdsImu1
      pageAdTargetType={pageAdTarget}
      uniqueSectionName={uniqueSectionName}
      gsChannels={gsChannels}
    />
  );
  let adsMidArticle = (
    <AdsMidArticle
      uniqueSectionName={uniqueSectionName}
      pageAdTargetType={pageAdTarget}
      gsChannels={gsChannels}
    />
  );
  if (isInfiniteScrollArticle) {
    adsImu1 = (
      <CompanionAdsImu1
        uniqueSectionName={uniqueSectionName}
        pageAdTargetType={pageAdTarget}
        index={pageNumber}
        gsChannels={gsChannels}
      />
    );
    adsMidArticle = (
      <CompanionAdsMidArticle
        uniqueSectionName={uniqueSectionName}
        pageAdTargetType={pageAdTarget}
        index={pageNumber}
        gsChannels={gsChannels}
      />
    );
  }

  return (
    <div key={pageNumber} data-testid="article-body">
      {trimmedParagraphs &&
        trimmedParagraphs.map((element, index) => {
          let bannersAndAds = null;

          if (paragraphCount === 0 && showGenGSubBanner) {
            bannersAndAds = (
              <>
                <SubscriptionBanner
                  isInfiniteScrollArticle={isInfiniteScrollArticle}
                  pageNumber={pageNumber}
                  isGenG={true}
                />
                {adsImu1}
              </>
            );
          } else if (paragraphCount === 1) {
            bannersAndAds = (
              <>
                {showSubBanner && (
                  <SubscriptionBanner
                    isInfiniteScrollArticle={isInfiniteScrollArticle}
                    pageNumber={pageNumber}
                  />
                )}
                {showRegBanner && (
                  <RegistrationBanner
                    isInfiniteScrollArticle={isInfiniteScrollArticle}
                    pageNumber={pageNumber}
                  />
                )}
                {adsImu1}
              </>
            );
          } else if (paragraphCount === 5) {
            bannersAndAds = <>{adsMidArticle}</>;
          } else if (
            paragraphCount === 6 &&
            contentAccess === LabelCardIconVariety.FREE
          ) {
            bannersAndAds = (
              <div className="max-w-[825px] mb-8">
                <ArticleCardCarousel
                  renderedCount={3}
                  renderItems={latestRegisteredArticle}
                  className="mt-sm"
                />
              </div>
            );
          }

          switch (element.type) {
            case "paragraph":
              paragraphCount++;
              return (
                <React.Fragment key={index}>
                  <Paragraph testid={`paragraph-${index}`}>
                    {ApplyParagraphAnnotations(
                      element.value,
                      element.annotations,
                    )}
                  </Paragraph>
                  {bannersAndAds}
                </React.Fragment>
              );

            case "sub_head":
              return (
                <React.Fragment key={index}>
                  <SubHead element={element} />
                </React.Fragment>
              );

            case "image": {
              const imageHeight =
                element.image?.height && element.image.height >= 1460
                  ? 1460
                  : 550;
              return (
                <ArticleImage
                  key={index}
                  index={index.toString()}
                  url={element.image?.url}
                  alt={element.caption}
                  caption={element.caption}
                  credit={element.credit}
                  height={imageHeight}
                  className="my-12"
                />
              );
            }

            case "embed":
              return (
                <React.Fragment key={index}>
                  <Embed className="my-lg" src={element.embedUrl} />
                </React.Fragment>
              );

            case "pull_quote":
              return (
                <React.Fragment key={index}>
                  <PullQuoteCard
                    quote={element.quote}
                    quotee={element.quotee}
                    quoteLabel={element.quoteLabel}
                    quoteCredit={element.quoteCredit}
                  />
                </React.Fragment>
              );

            case "list_bulleted":
            case "list_numbered": {
              const listType = element.type === "list_bulleted" ? "ol" : "ul";
              const listItems = element.listItem;
              const listStyle =
                element.listStyle === "decimal" ? "list-decimal" : "list-disc";
              return (
                <React.Fragment key={index}>
                  <ParagraphList
                    listStyle={listStyle}
                    listType={listType}
                    listItems={listItems}
                  />
                </React.Fragment>
              );
            }

            case "youtube_video": {
              const url = `${YOUTUBE_WATCH_URL}${element.youtubeId}`;
              return (
                <React.Fragment key={index}>
                  <YouTubeEmbedCustom url={url} className="my-lg" />
                </React.Fragment>
              );
            }

            case "brightcove_video": {
              return (
                <React.Fragment key={index}>
                  <WrapperContainer className="block my-lg">
                    <BrightcoveVideo videoId={element.videoId} />
                  </WrapperContainer>
                </React.Fragment>
              );
            }

            default:
              return null;
          }
        })}
      {isArrayWithElements(relatedArticles) && (
        <RelatedArticle relatedArticles={relatedArticles} />
      )}
      {showNormalBanner && isCasScriptReady && <CASBlock {...casBlockProps} />}
    </div>
  );
}
