import CollapseIcon from "@assets/wordpuzzle/collapse-icon.svg";
import CollapseIconMob from "@assets/wordpuzzle/collapse-icon-mob.svg";
import DesktopLogoImg from "@assets/wordpuzzle/Desktop_logo-img_01.svg";
import DesktopMainImg from "@assets/wordpuzzle/Desktop_Main-img_01.png";
import MobileMainImg from "@assets/wordpuzzle/Mobile_Main-img_01.png";

import QuestionCard from "./QuestionCard";

type ResultsProps = {
  isMobile: boolean;
  toggleExpand: () => void;
  handleQuestion: () => void;
  clkCount: number;
  questionNum: number;
  questions: { [key: string]: string }[];
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctMalayWord: string;
  handleWordClick: (word: string) => void;
};

function QuestionDetailSection({
  isMobile,
  toggleExpand,
  handleQuestion,
  clkCount,
  questionNum,
  questions,
  englishWord,
  options,
  selectedWord,
  correctMalayWord,
  handleWordClick,
}: ResultsProps): JSX.Element {
  return (
    <>
      {!isMobile ? (
        <div
          className="px-4 py-2 relative flex items-center justify-center bg-white-300 h-[85px]"
          style={{ boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)" }}
        >
          <span className="relative w-[170px]">
            <img
              src={DesktopMainImg}
              className="absolute top-[-73px] left-[12px] w-[170px] h-[116px]"
              alt="Man Icon"
            />
          </span>
          <div className="relative mr-15 mb-15 w-[300px] h-[75px] mb-sm mr-[18px] left-sm">
            <span className="quiz-logo">
              <img
                src={DesktopLogoImg}
                className="w-[294px] h-[61px] ml-[-5px]"
                alt="Quiz Logo"
              />
            </span>
            <span className="justify-center mb-2 relative flex mt-[-9px]">
              <span className="block text-black-100 font-primary text-xs font-semimedium h-[17px]">
                Pilih perkataan yang betul
              </span>
              <span className="text-black font-primary text-xs">
                <button
                  className="no-underline bg-transparent flex cursor-pointer hover:underline hover:decoration-pink-500 pl-1 text-pink-500"
                  onClick={
                    clkCount <= 3 && questionNum <= 3
                      ? handleQuestion
                      : undefined
                  }
                >
                  {questionNum}/3
                </button>
              </span>
            </span>
          </div>
          {questions.length > 0 && (
            <QuestionCard
              englishWord={englishWord}
              options={options}
              selectedWord={selectedWord}
              correctMalayWord={correctMalayWord}
              onWordClick={handleWordClick}
            />
          )}
          <span className="w-[24px] h-[24px] relative top-[-45px] lg:left-sm">
            <button onClick={toggleExpand}>
              <img
                src={CollapseIcon}
                className="h-6 w-6 cursor-pointer"
                alt="Collapse Icon"
              />
            </button>
          </span>
        </div>
      ) : (
        <div
          className="bg-white-300"
          style={{ boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)" }}
        >
          <div className="justify-start flex">
            <div className="ml-[7px]">
              <img
                src={MobileMainImg}
                alt="logo"
                className="w-[187px] sm:w-[140px] h-full sm:h-[136px]"
              />
            </div>
            <div className="mt-sm w-[55%] mb-sm">
              <span className="relative flex mt-[-9px]">
                <span className="block text-black font-primary text-xxs font-semibold">
                  Pilih perkataan yang betul
                </span>
                <span className="text-blue-100 font-primary text-xxs font-semibold">
                  <button
                    className="no-underline bg-transparent flex cursor-pointer hover:underline pl-1 text-xxs"
                    onClick={
                      clkCount <= 3 && questionNum <= 3
                        ? handleQuestion
                        : undefined
                    }
                  >
                    {questionNum}/3
                  </button>
                </span>
              </span>
              {questions.length > 0 && (
                <QuestionCard
                  englishWord={englishWord}
                  options={options}
                  selectedWord={selectedWord}
                  correctMalayWord={correctMalayWord}
                  onWordClick={handleWordClick}
                  isMobile={isMobile}
                />
              )}
            </div>
            <span className="h-6 w-6 absolute top-[-15px] right-0">
              <button onClick={toggleExpand}>
                <img
                  src={CollapseIconMob}
                  className="h-6 w-6 bottom-7 left-10 cursor-pointer"
                  alt="Collapse Icon"
                />
              </button>
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default QuestionDetailSection;
