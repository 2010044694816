import desktopImage from "@assets/webinar/Desktop-If-no-webinar.png";
import mobileImage from "@assets/webinar/Mobile-If-no-webinar.png";

export default function NoWebinar() {
  return (
    <div className="flex justify-center mb-lg">
      <img src={desktopImage} alt="No webinar" className="hidden md:block" />
      <img src={mobileImage} alt="No webinar" className="md:hidden" />
    </div>
  );
}
