import BHBasePage from "@pages/BHBasePage";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import type { ReactElement } from "react";

type Props = Readonly<{
  message?: string;
}>;

const DEFAULT_MESSAGE = "Server Error!";

/**
 * Error page: this will never render on Server.
 * Added only to handle client side error scenario.
 */
export default function ErrorPage({
  message = DEFAULT_MESSAGE,
}: Props): ReactElement {
  return (
    <BHBasePage
      uniqueSectionName=""
      pageAdTargetValue={PageAdTargetingTypeEnum.LISTING}
    >
      <div>
        <h1>Something went wrong :(</h1>
        <p>{message}</p>
      </div>
    </BHBasePage>
  );
}
