import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import MetaTags from "@elements/MetaTags/MetaTags";

export default function FoodMapMetaData() {
  const metaTags: MetaTagsProps = {
    title:
      "Halal Restaurant Singapore, Halal Restaurant near me - Berita Harian Halal Food Map",
    description:
      "Halal Restaurant Singapore – Find Halal Restaurant near me with berita harian halal food map. Browse BH Halal Food Map and find your favorite halal food in Singapore.",
    slug: "foodmap",
    keywords: [
      "halal",
      "halal food",
      "halal food near me",
      "food",
      "makanan",
      "makanan halal",
      "muslim food",
      "nice restaurants",
      "restaurants",
      "good restaurants",
      "good food",
      "singapore food",
      "halal restaurant near me",
    ],
    image: { url: "https://static1.beritaharian.sg/fb-preview-image.jpg" },
    addAppleMobileTags: true,
  };

  return <MetaTags {...metaTags} />;
}
