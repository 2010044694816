/**
 * Creates a debounced function that delays the invocation of the provided function
 * until after the specified wait time has elapsed since the last time the debounced
 * function was called. This helps in preventing the function from being called
 * multiple times in quick succession.
 *
 * @template T - The type of the function to be debounced.
 *
 * @param {T} func - The function to be debounced.
 * @param {number} wait - The number of milliseconds to delay.
 *
 * @returns {(...args: Parameters<T>) => void} - A debounced version of the provided function.
 *
 * @example
 * // Example usage:
 * const debouncedFunction = debounce(() => {
 *   console.log('Function executed after 300ms of inactivity');
 * }, 300);
 *
 * // Calling the debounced function multiple times within 300ms will only execute the function once.
 * window.addEventListener('resize', debouncedFunction);
 */
export default function debounce<T extends (...args: unknown[]) => void>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;
  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
