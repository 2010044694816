import DesktopLogoImg from "@assets/wordpuzzle/Desktop_logo-img_01.svg";
import DesktopSmallImg from "@assets/wordpuzzle/Desktop_Small-img_01.png";
import ExpandIcon from "@assets/wordpuzzle/expand-icon.svg";
import ExpandIconMob from "@assets/wordpuzzle/expand-icon-mob.svg";
import MobileSmallImg from "@assets/wordpuzzle/Mobile_Small-img_01.png";
import MobileSmallLogo from "@assets/wordpuzzle/Mobile_Small-logo_svg.svg";

type ResultsProps = {
  isMobile: boolean;
  toggleExpand: () => void;
};

function CollapseSection({
  isMobile,
  toggleExpand,
}: ResultsProps): JSX.Element {
  return (
    <>
      {!isMobile && (
        <div
          className="bg-white-300 flex justify-center h-[50px]"
          style={{
            boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)",
          }}
        >
          <div className="flex items-center w-[620px] h-[33px] py-0 px-sm gap-xxs relative">
            <span className="block relative w-[95px]">
              <img
                src={DesktopSmallImg}
                className="absolute top-[-27px] left-[-4px] w-[90px] h-[60px]"
                alt="Man Icon"
              />
            </span>
            <span className="ml-[-15px]">
              <img
                src={DesktopLogoImg}
                className="w-[160px] ml-[-5px] mr-5"
                alt="Quiz Logo"
              />
            </span>
            <span className="text-gray-800 text-xs font-secondary leading-5 not-italic font-semibold pt-xs ml-[-15px]">
              Uji pengetahuan anda dalam bahasa Melayu
            </span>
            <span className="absolute top-[-13px] right-sm w-[22px]">
              <button onClick={toggleExpand}>
                <img
                  src={ExpandIcon}
                  className="h-6 w-6 bottom-7 left-10 cursor-pointer"
                  alt="Expand Icon"
                />
              </button>
            </span>
          </div>
        </div>
      )}
      {isMobile && (
        <div
          className=" bg-white-300 block h-[50px]"
          style={{
            boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)",
          }}
        >
          <div className="flex items-center h-auto py-0 px-sm gap-xxs">
            <div className="block relative w-[95px]">
              <img
                src={MobileSmallImg}
                className="absolute top-[-36px] left-[-4px] w-[90px] h-[60px]"
                alt="Man Icon"
              />
            </div>
            <div>
              <span className="quiz-logo">
                <img src={MobileSmallLogo} className="" alt="Quiz Logo" />
              </span>
              <div className="text-gray-700 text-xs font-secondary font-semibold leading-3 pt-1">
                Uji pengetahuan anda dalam bahasa Melayu
              </div>
            </div>
            <span className="relative h-6 w-6 top-[-26px]">
              <button onClick={toggleExpand}>
                <img
                  src={ExpandIconMob}
                  className="h-6 w-6 bottom-7 left-10 cursor-pointer"
                  alt="Expand Icon"
                />
              </button>
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default CollapseSection;
