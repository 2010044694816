import MySphLoginButton from "@components/Header/MySphLoginButton";

export function NormalPaywall(userDisplayName: string | undefined) {
  return (
    <div className="flex text-md items-center gap-1 px-xs">
      {userDisplayName ? (
        <span data-testid="subscribe-msg" className="text-black-100">
          Hello {userDisplayName}! Pilih pilihan pembelian anda di bawah:
        </span>
      ) : (
        <>
          <MySphLoginButton
            buttonText="Log masuk"
            iconFont={true}
            textStyle="text-blue-100 underline"
          />
          <span className="text-black-100">untuk terus membaca</span>
        </>
      )}
    </div>
  );
}

export function NormalGenGPaywal(userDisplayName: string | undefined) {
  return (
    <div className="relative text-md px-xs">
      {userDisplayName ? (
        <span className="text-black-100">{GENG_SUB_REG_COPY}</span>
      ) : (
        <>
          <span className="text-black-100">
            Artikel-artikel ini adalah untuk pelanggan Gen G sahaja.{" "}
          </span>
          <MySphLoginButton
            buttonText="Log masuk"
            textOnly={true}
            textStyle="text-blue-100 underline"
            buttonStyle="inline"
          />
          <span className="text-black-100">
            {" "}
            akaun SPH Media anda untuk membaca kandungan Gen G.
          </span>
        </>
      )}
    </div>
  );
}

export const GENG_SUB_REG_COPY =
  "Artikel-artikel ini adalah untuk pelanggan Gen G sahaja. Tekan pautan di bawah untuk melanggan.";
