import SideBlockAds from "@container/SideBlockAds";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import type { KeywordPayloadResponseType } from "./Keyword.server";
import KeywordArticlesList from "./KeywordArticleList";
import KeywordMetaData from "./KeywordMetaData";

export function KeywordPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<KeywordPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const keywordArticles = dataLoaderResponse.payload.data;
  const tagAlias = dataLoaderResponse.payload.tagAlias;
  const articleCount = dataLoaderResponse.payload.totalArticlesCount;
  const sectionName = "tags";
  let tagName = "";

  for (const tag of keywordArticles[0].tags || []) {
    if (tag.alias?.includes(tagAlias)) {
      if (tag.name !== undefined) {
        tagName = tag.name;
        break;
      }
    }
  }

  const pageAdTargetValue = PageAdTargetingTypeEnum.TAG;

  const { gaData, metaTags } = KeywordMetaData({ tagName, tagAlias });
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: sectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="flex flex-col lg:flex-row w-full gap-4">
        <KeywordArticlesList
          articles={keywordArticles}
          tagAlias={tagAlias}
          tagName={tagName}
          articleCount={articleCount}
          sectionName={sectionName}
        />
        <SideBlockAds
          uniqueSectionName={sectionName}
          adsOnly={true}
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
    </BHBasePage>
  );
}
