import dummyWebinarImg from "@assets/images/default/Commentary.jpg";
import ShareSocial, { SocialVariant } from "@elements/ShareSocial/ShareSocial";
import Heading from "@elements/Typography/Heading";
import Link from "@elements/Typography/Link";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { ProcessedWebinarData } from "@transformer/useOSWebinarResponse";

type WebinarCardProps = {
  webinarData: ProcessedWebinarData;
  addShadowWrapper?: boolean;
};
export default function WebinarCard({
  webinarData,
  addShadowWrapper = false,
}: WebinarCardProps) {
  const { title, description, startDate, zoomLink, webinarImage } = webinarData;
  const bhCustomEvents = useBHCustomEventAnalyticsCall();

  const socialIconList: SocialVariant[] = ["facebook", "telegram", "whatsapp"];
  const boxShadowStyle = addShadowWrapper
    ? { boxShadow: "0px 4px 4px 0px #00000040" }
    : {};
  const registerButtonClicked = () => {
    bhCustomEvents("webinar", "register", title);
  };

  return (
    <div
      data-testid="webinar-card"
      className={
        addShadowWrapper
          ? "px-lg py-sm md:px-xxl md:py-lg mb-lg md:mb-xl"
          : "mb-sm"
      }
      style={boxShadowStyle}
    >
      {/* Desktop webinar card. */}
      <div className="hidden md:flex gap-lg">
        <div className="flex gap-2 flex-col basis-7/12">
          <div className="font-secondary font-semibold text-xl text-pink-400">
            {startDate}
          </div>
          <Heading className="font-semibold text-xxxl text-blue-400">
            {title}
          </Heading>
          <span className="font-regular text-sm text-black-200 flex-grow">
            {description}
          </span>
          <div className="flex justify-between">
            <Link
              destination={zoomLink}
              relativePath={false}
              target="_blank"
              className="bg-blue-100 px-xl py-xxs rounded-[30px] h-[40px]"
              handleClick={registerButtonClicked}
            >
              <span className="text-white-100 text-[17px]">
                Daftar untuk webinar
              </span>
            </Link>
            <div className="flex gap-2">
              {socialIconList.map((variant, index) => (
                <ShareSocial
                  variant={variant}
                  pageTitle={title}
                  pagePath={zoomLink}
                  key={index}
                  isAbsolutePagePath={true}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="basis-5/12">
          <img
            src={webinarImage?.url || dummyWebinarImg}
            alt={title}
            width={458}
            height={296}
          />
        </div>
      </div>

      {/* Mobile webinar card. */}
      <div className="flex md:hidden flex-col gap-sm">
        <div className="font-secondary font-semibold text-md text-pink-400">
          {startDate}
        </div>
        <Heading className="font-semibold text-[22px] text-blue-400">
          {title}
        </Heading>
        <img
          src={webinarImage?.url || dummyWebinarImg}
          alt={title}
          width={330}
          height={205}
        />
        <span className="font-regular text-sm text-black-200 flex-grow">
          {description}
        </span>
        <div className="flex gap-2">
          {socialIconList.map((variant, index) => (
            <ShareSocial
              variant={variant}
              pageTitle={title}
              pagePath={zoomLink}
              key={index}
              isAbsolutePagePath={true}
            />
          ))}
        </div>
        <div className="flex">
          <Link
            destination={zoomLink}
            relativePath={false}
            target="_blank"
            className="bg-blue-100 px-xl py-xxs rounded-[30px] w-full h-[35px] text-center"
            handleClick={registerButtonClicked}
          >
            <span className="text-white-100 text-xs">Daftar untuk webinar</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
