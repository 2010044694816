import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function WebinarMetaData(section: string): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: section,
    title: `${capitalize(section)}_Index`,
    section: section,
    contentcat: "1",
    contenttype: "webinar",
  };

  const metaTags: MetaTagsProps = {
    title: "Webinar, Berita - Beritaharian.sg",
    description: "Sertai acara webinar anjuran Berita Harian",
    slug: section,
  };

  return {
    gaData,
    metaTags,
  };
}
