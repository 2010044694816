import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import type { CreateLoadMoreHandlerProps } from "@elements/LoadMore/CreateLoadMoreHandler";
import { CreateLoadMoreHandler } from "@elements/LoadMore/CreateLoadMoreHandler";
import useSetFirstRenderedArticleIds from "@elements/LoadMore/SetFirstRenderedArticleIds";
import {
  fetchMorePaywallStoriesData,
  fetchMoreStoriesData,
} from "@pages/Section/Section.server";
import ArticleCard from "@src/app/components/Elements/Card/Article/ArticleCard";
import LoadMore from "@src/app/components/Elements/LoadMore/LoadMore";
import { LabelCardIconVariety } from "@src/app/components/Icon/LabelCardIcon";
import { displayAfter } from "@src/app/helper/utils";
import type { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import type {
  FetchMoreArticlesFunction,
  MoreArticlesRequestData,
} from "@typings/MoreArticlesApi";
import React, { useState } from "react";

const PAGE_SIZE = 8;
const INITIAL_ARTICLE_COUNT = 9;

type SectionArticlesListProps = {
  sectionArticles: ProcessedArticleData[];
  sectionName: string | undefined;
  adsSectionName: string | undefined;
  paywall: LabelCardIconVariety | null;
};

export default function SectionArticlesList({
  sectionArticles,
  sectionName,
  adsSectionName,
  paywall,
}: SectionArticlesListProps) {
  const headerArticle = sectionArticles[0];
  const onlineStories = [...sectionArticles.slice(1)];
  const contentAccess = headerArticle.contentAccess;

  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean = sectionArticles.length == INITIAL_ARTICLE_COUNT;

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(sectionArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? sectionArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: paywall !== null ? contentAccess : sectionName,
      sort: lastLoadedArticle && lastLoadedArticle.sort,
    };
    const fetchFunction: FetchMoreArticlesFunction =
      paywall !== null ? fetchMorePaywallStoriesData : fetchMoreStoriesData;
    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };

  return (
    <>
      <div className="w-12/12 lg:w-8/12">
        <ul>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {/* render the first article as large */}
            <li className="col-span-1 md:col-span-3 lg:col-span-4">
              <ArticleCard
                displayWidth={823}
                displayHeight={549}
                elements={{
                  hasHeader: true,
                  hasIcon: true,
                  hasParagraph: true,
                  hasTitle: false,
                }}
                overrideStyle={{
                  imageStyle: "w-full",
                  cardWrapper: "flex flex-col",
                  contentWrapper: "flex flex-col lg:gap-y-4 lg:gap-y-0",
                  iconStyle: "lg:mb-xs",
                  description: "lg:mt-md",
                }}
                {...headerArticle}
              />
            </li>
            {/* render the first article as 4 per row */}
            {[...onlineStories, ...loadMoreData].map((context, index) => (
              <React.Fragment key={context.id}>
                {displayAfter(index, PAGE_SIZE) && (
                  <li
                    data-testid={index}
                    className="col-span-1 md:col-span-3 lg:col-span-4"
                  >
                    <CompanionAdsLb
                      uniqueSectionName={adsSectionName}
                      index={index / PAGE_SIZE}
                      pageAdTargetType={PageAdTargetingTypeEnum.LISTING}
                    />
                  </li>
                )}
                <li>
                  <ArticleCard
                    displayWidth={454}
                    displayHeight={303}
                    elements={{
                      hasIcon: true,
                      hasParagraph: true,
                    }}
                    overrideStyle={{
                      description:
                        "font-primary text-xs leading-[150%] font-regular mb-sm hidden md:block",
                    }}
                    {...context}
                  />
                </li>
              </React.Fragment>
            ))}
            {showLoadMore ? (
              <LoadMore
                rootClassName="my-4"
                onLoadMore={handleLoadMore}
                hasMore={hasMoreStories}
                loadText="Lagi Cerita"
              />
            ) : null}
          </div>
        </ul>
      </div>
    </>
  );
}
