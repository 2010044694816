function getLightboxUrl(): string {
  return getEnvVar("VITE_MYSPH_LIGHTBOX_URL");
}

function getIssuer(): string {
  return getEnvVar("VITE_MYSPH_ISSUER");
}

function getClientId(): string {
  return getEnvVar("VITE_MYSPH_CLIENT_ID");
}

function getEnvVar(variableName: string) {
  const rawValue = import.meta.env[variableName] as string | undefined | null;
  const value = rawValue ?? "";

  if (value === "") {
    console.error(
      `Missing ${variableName} env variable. Authentication will NOT work properly!`,
    );
  }
  return value;
}

export { getLightboxUrl, getIssuer, getClientId };
