/**
 * Company: SPHMedia
 * Description: This is the base page component for all BH pages
 */

import CASBlock from "@components/CASBlock/CASBlock";
import CatfishAd from "@components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import PrestitialAd from "@components/Elements/Advertisement/PrestitialAd";
import { Footer } from "@components/Footer/Footer";
import { Header } from "@components/Header/Header";
import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import { AdsLb1 } from "@elements/Advertisement/variant/AdsLb";
import type { GADataProps } from "@elements/GAData/GAData";
import GAData from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import MetaTags from "@elements/MetaTags/MetaTags";
import { CAS_EI_MODEL_ID } from "@helper/getEnvVariables";
import { useNeuronPageViewPush } from "@hooks/useAnalyticsCall/useNeuronPageViewPush";
import useOKTAUserInfo from "@hooks/useOktaUserInfo";
import useScript from "@hooks/useScript/useScript";
import useSendPermutiveIdentify from "@hooks/useSendPermutiveIdentify";
import { MainContainer } from "@src/app/components/Container/MainContainer";
import { AdTargetingType, PageAdTargetValue } from "@typings/Ads.d";
import { ReactElement } from "react";

export type AdsSettings = {
  isPrestitialEnabled: boolean | undefined;
  isCatfishEnabled: boolean | undefined;
  topOverlayImpressions: number;
  topOverlayValidity: number;
  isOutbrainEnabled: boolean | undefined;
};

export type BHArticleBasePageProps = {
  uniqueSectionName?: string;
  children?: React.ReactNode;
  hasLb1Ads?: boolean;
  gaData?: GADataProps;
  metaTags?: MetaTagsProps;
  pageAdTargetValue: PageAdTargetValue;
  suppliedAdsSettings: AdsSettings;
  gsChannels?: string[];
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function BHArticleBasePage({
  uniqueSectionName,
  children,
  hasLb1Ads = true,
  gaData,
  metaTags,
  pageAdTargetValue,
  suppliedAdsSettings,
  gsChannels,
}: BHArticleBasePageProps): ReactElement {
  useOKTAUserInfo();
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX as string,
  });
  useNeuronPageViewPush();
  useSendPermutiveIdentify();

  const pageAdTarget = pubPageAdTargeting[
    pageAdTargetValue
  ] as AdTargetingType<PageAdTargetValue>;

  return (
    <>
      {gaData && <GAData {...gaData} />}
      {metaTags && <MetaTags {...metaTags} />}
      <MainContainer>
        <Header />
        <div className="w-full px-sm">
          {hasLb1Ads && (
            <AdsLb1
              uniqueSectionName={uniqueSectionName}
              pageAdTargetType={pageAdTargetValue}
              gsChannels={gsChannels}
            />
          )}
          <PrestitialAd
            slot={GoogleAdsSlotFactory.prestitial(uniqueSectionName)}
            isPrestitialEnabled={suppliedAdsSettings.isPrestitialEnabled}
            pubAdTargeting={[pageAdTarget]}
            topOverlayImpressions={suppliedAdsSettings.topOverlayImpressions}
            topOverlayValidity={suppliedAdsSettings.topOverlayValidity}
            gsChannels={gsChannels}
          />
          {children}
        </div>
      </MainContainer>
      <Footer />
      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish(uniqueSectionName)}
        isCatfishEnabled={suppliedAdsSettings.isCatfishEnabled}
        pubAdTargeting={[pageAdTarget]}
        gsChannels={gsChannels}
      />
      <CASBlock casId={CAS_EI_MODEL_ID} />
    </>
  );
}
