import { UrlMatch } from "@app/data.ts";
import fetchBHApi from "@helper/fetchBHApi";
import fetchCass from "@helper/fetchCaas";
import { isSectionMatchResult } from "@pages/Section/Section.server";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { KeywordPageQuery } from "@src/app/caas/KeywordPageQueries";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOSResponse";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type {
  OSResponse,
  PartialCueArticleWithCampaign,
} from "@typings/Opensearch";

export type KeywordPayloadResponseType = {
  data: ProcessedArticleData[];
  tagAlias: string;
  totalArticlesCount: number;
};

export const fetchKeywordData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<KeywordPayloadResponseType, string>
> => {
  const tagAlias = isSectionMatchResult(urlComponent)
    ? urlComponent.params.level1
    : "";

  try {
    const data = (await fetchCass({
      searchQuery: KeywordPageQuery(tagAlias),
    })) as OSResponse<PartialCueArticleWithCampaign>;

    const hits = data.payload.hits.total.value;
    if (hits === 0) {
      return { type: ResponseType.CLIENT_ERROR, statusCode: 404 };
    }

    const processedData = getOSProcessedArticleData(data, true, 200);

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        data: processedData,
        tagAlias: tagAlias,
        totalArticlesCount: hits,
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchMoreTagsStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "more-tags-stories",
    "POST",
    {
      tagAlias: requestData.filterArticlesBasedOn,
      sort: requestData.sort,
    },
  );

  return response;
}
