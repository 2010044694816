import { useEffect, useState } from "react";

import { fetchGsChannels } from "../pages/Article/Article.server";

export function useGrapeshotData(urlPath: string) {
  const [gsChannels, setGsChannels] = useState<string[]>([]);

  useEffect(() => {
    const callFetch = async () => {
      if (urlPath === "") return;
      const gsData = await fetchGsChannels(urlPath);
      setGsChannels(gsData);
    };

    void callFetch();
  }, [urlPath]);

  return { gsChannels };
}
