import ArticleCarousel from "@components/Carousel/ArticleCarousel";
import SideBlockAds from "@components/Container/SideBlockAds";
import LabelCardIcon from "@components/Icon/LabelCardIcon";
import ArticleParagraph from "@elements/ArticleParagraph/ArticleParagraph";
import AttachmentCard from "@elements/AttachmentCard/AttachmentCard";
import AuthorCard from "@elements/Card/Author/AuthorCard";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import ShareSocial, { SocialVariant } from "@elements/ShareSocial/ShareSocial";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { StandFirst } from "@elements/Typography/Paragraph";
import { RenderTags } from "@elements/Typography/Tags";
import { SectionTitle } from "@elements/Typography/Title";
import { isArrayWithElements } from "@helper/utils";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetValue } from "@typings/Ads.d";

export type ArticleContentCardProps = {
  mainArticle: ProcessedArticleData;
  latestRegisteredArticle: ProcessedArticleData[];
  pageAdTargetValue: PageAdTargetValue;
  pageNumber: number;
  isInfiniteScrollArticle?: boolean;
  isOutbrainEnabled: boolean;
  gsChannels?: string[];
};

export default function ArticleContentCard({
  mainArticle,
  latestRegisteredArticle,
  pageAdTargetValue,
  pageNumber,
  isInfiniteScrollArticle = false,
  isOutbrainEnabled = false,
  gsChannels,
}: ArticleContentCardProps) {
  const {
    sectionName,
    uniqueSectionName,
    title,
    tags,
    authors,
    date,
    dateCreated,
    time,
    articleBody,
    standFirst,
    contentAccess,
    urlPath,
    imageGallery,
    relatedArticles,
    attachments,
    id,
  } = mainArticle;
  const socialIconList = ["facebook", "telegram", "plainWhatsapp", "newsPost"];
  const pageUrl = `${urlPath}`;

  return (
    <div className="flex flex-col lg:flex-row w-full gap-6">
      <div className="flex flex-col lg:px-xs">
        <div className="flex items-center gap-3 w-full">
          <SectionTitle
            uniqueSectionName={uniqueSectionName}
            className="mb-0"
            sectionName={sectionName}
          />
          <LabelCardIcon contentAccess={contentAccess} />
        </div>
        <Heading
          Element="h1"
          className="font-secondary leading-2xl text-black-200 text-xl lg:text-4xl font-bold"
        >
          {title}
        </Heading>
        {standFirst && <StandFirst className="my-6">{standFirst}</StandFirst>}
        {/* Tags dont dont render If alias not available in payload*/}
        <RenderTags tagsWrapper="mb-6" tags={tags} />
        {isArrayWithElements(authors) &&
          authors.map((author) => (
            <AuthorCard
              key={author.name}
              author={author}
              displayHeight={36}
              displayWidth={36}
              imageWrapper="w-[50px]"
              authorCardWrapper="gap-2"
              hasEmail={false}
            />
          ))}
        <Date date={date} time={time} variant={"or"} className="my-2" />

        <div className="flex self-end gap-1 mb-6">
          {socialIconList.map((variant, index) => (
            <ShareSocial
              variant={variant as SocialVariant}
              pagePath={pageUrl}
              pageTitle={title}
              dateCreated={dateCreated}
              key={index}
            />
          ))}
        </div>
        {isArrayWithElements(imageGallery) && (
          <ArticleCarousel
            renderItems={imageGallery}
            sectionName={sectionName}
          />
        )}

        <ArticleParagraph
          articleId={id}
          contentAccess={contentAccess}
          articleBody={articleBody}
          uniqueSectionName={uniqueSectionName}
          relatedArticles={relatedArticles}
          latestRegisteredArticle={latestRegisteredArticle}
          pageNumber={pageNumber}
          isInfiniteScrollArticle={isInfiniteScrollArticle}
          gsChannels={gsChannels}
        />

        {isArrayWithElements(attachments) && (
          <div className="my-md">
            {attachments.map((attachment, idx) => (
              <AttachmentCard key={idx} attachment={attachment} />
            ))}
          </div>
        )}

        <div className="flex self-end gap-1 mt-6">
          {socialIconList.map((variant, index) => (
            <ShareSocial
              variant={variant as SocialVariant}
              pagePath={pageUrl}
              pageTitle={title}
              dateCreated={dateCreated}
              key={index}
            />
          ))}
        </div>
        {isOutbrainEnabled && urlPath && <Outbrain pathName={urlPath} />}
      </div>

      <SideBlockAds
        uniqueSectionName={uniqueSectionName}
        className="w-full max-w-[400px] py-xs px-6 sticky top-[130px]"
        adsOnly={true}
        hasImu3={false}
        pageAdTargetingProps={pageAdTargetValue}
        forInfiniteScroll={isInfiniteScrollArticle}
        pageNumber={pageNumber}
        gsChannels={gsChannels}
      />
    </div>
  );
}
