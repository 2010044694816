import { SECTION_LIST, SectionDetails } from "@header/headerMenuConstants";
import { FeatureFlags } from "@src/app/featureFlag/featureFlags";
import { getFeatureFlag } from "@src/app/helper/utils";
import { type ReactElement, useEffect, useState } from "react";

import { HeaderMenuList } from "./HeaderMenuList";

export type SectionQuickLinksProps = Readonly<{
  testId?: string;
}>;

export function SectionQuickLinks({
  testId = "desktop-quick-links",
}: SectionQuickLinksProps): ReactElement {
  const [sectionList, setSectionList] = useState<SectionDetails[]>([]);

  useEffect(() => {
    const filter_header = async () => {
      const genG_Flags: string = await getFeatureFlag(FeatureFlags.GEN_G);
      const webinar: string = await getFeatureFlag(FeatureFlags.WEBINAR);
      setSectionList(
        SECTION_LIST.filter((item) => {
          if (
            (item.title === "Gen G" && genG_Flags === "1") ||
            (item.title === "Webinar" && webinar === "1")
          ) {
            return true;
          }
          return item.title !== "Gen G" && item.title !== "Webinar";
        }),
      );
    };
    void filter_header();
  }, []);

  return (
    <div
      data-testid={`${testId}-quickLinks`}
      className="h-fit border-t-blue-400 border-t-[2px] border-b-grey-300 border-b-[3px] text-sm font-semimedium"
    >
      <div
        data-testid={`${testId}-quickLinksInner`}
        className="flex items-center justify-between lg:pr-xxl"
      >
        <div
          data-testid={`${testId}-topicsListWrapper`}
          className="h-full overflow-x-scroll lg:overflow-x-visible"
        >
          <HeaderMenuList testId={testId} items={sectionList} type="topics" />
        </div>
        <div
          data-testid={`${testId}-subscriptionLink`}
          className="mr-xs hidden"
        >
          <a
            href="https://subscribe.sph.com.sg/publications-bh/?utm_campaign=bh_subscription&utm_medium=sph-publication&utm_source=bh&utm_content=subscribelink-header"
            className="block w-[92px] h-[26px] rounded-[12px] border-orange-100 border bg-orange-100 text-white-100 text-center text-xxs font-semibold leading-[22px] hover:text-white-100 visited:text-white-100"
          >
            Langgan
          </a>
        </div>
      </div>
    </div>
  );
}
