import { replaceDashAndWithSpace } from "@helper/stringTransform";
import { findStyleBySection } from "@src/app/helper/utils";
import { twMerge } from "tailwind-merge";

type SectionTitleProps = {
  sectionName?: string;
  uniqueSectionName?: string;
  className?: string;
};

export function SectionTitle({
  sectionName,
  uniqueSectionName = sectionName,
  className,
}: SectionTitleProps) {
  const { selectedColor } = findStyleBySection(uniqueSectionName) || {};

  return sectionName ? (
    <h1
      className={twMerge(
        "text-lg text-primary font-regular capitalize mb-2",
        `text-${selectedColor}`,
        className,
      )}
    >
      {replaceDashAndWithSpace(sectionName)}
    </h1>
  ) : null;
}
