import closeIcon from "@assets/header/menu/menuClose.svg";
import styles from "@header/Header.module.css";
import {
  MOBILE_MAIN_LIST,
  MOBILE_MISC_LIST,
  MOBILE_SECTION_LIST,
} from "@header/headerMenuConstants";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import { FeatureFlags } from "@src/app/featureFlag/featureFlags";
import { getFeatureFlag } from "@src/app/helper/utils";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";
import cx from "classnames";
import { ReactElement, useEffect, useState } from "react";

import { HeaderMenuList } from "./HeaderMenuList";
import MySphLoginButton from "./MySphLoginButton";
import UserInfo from "./UserInfo/UserInfo";

export type MobileMenuModalProps = {
  expandMenu: boolean;
  onClick: () => void;
};

export function MobileMenuModal({
  expandMenu,
  onClick,
}: MobileMenuModalProps): ReactElement {
  const { userInfo } = useUserInfo();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  const [mainList, setMainList] = useState(MOBILE_MAIN_LIST);

  useEffect(() => {
    const filter_header = async () => {
      const genG_Flags: string = await getFeatureFlag(FeatureFlags.GEN_G);
      const webinar: string = await getFeatureFlag(FeatureFlags.WEBINAR);
      setMainList(
        MOBILE_MAIN_LIST.filter((item) => {
          if (
            (item.title === "Gen G" && genG_Flags === "1") ||
            (item.title === "Webinar" && webinar === "1")
          ) {
            return true;
          }
          return item.title !== "Gen G" && item.title !== "Webinar";
        }),
      );
    };
    void filter_header();
  }, []);

  return (
    <>
      {expandMenu ? (
        <div className="fixed top-0 left-0 overflow-x-hidden w-full h-full bg-white-200 z-[999]">
          <div className={cx(styles.closeMenuWrapper)}>
            <button className={cx(styles.closeMobileMenu)} onClick={onClick}>
              <img
                className={cx(styles.iconCross)}
                src={closeIcon}
                alt="menu"
              />
            </button>
          </div>
          <div className={cx(styles.mobileMenuWrapper)}>
            <div className={cx(styles.mobileUserSection)}>
              {userDisplayName ? (
                <UserInfo
                  rootClassName="whitespace-nowrap pt-xs pb-xs"
                  userInfo={
                    {
                      ...userInfo,
                      lastname: userDisplayName,
                      loginId: OKTAUserInfo.loginid,
                      sub: OKTAUserInfo.reguserstatus,
                    } as UserInfoType
                  }
                />
              ) : (
                <MySphLoginButton
                  buttonStyle="gap-2"
                  iconWidth="20px"
                  iconHeight="19px"
                />
              )}
              <span className={cx(styles.mobileMenuDivider)}>|</span>
              <a
                href="https://subscribe.sph.com.sg/publications-bh/?utm_campaign=bh_subscription&utm_medium=sph-publication&utm_source=bh&utm_content=subscribelink-header"
                className={cx(styles.subscriptionLinkItem)}
              >
                Langgan
              </a>
            </div>
            <div className={cx(styles.mobileMainMenuListItems)}>
              <HeaderMenuList
                testId="mobile-main-links"
                items={mainList}
                type="mobileMain"
              />
              <HeaderMenuList
                testId="mobile-main-links"
                items={MOBILE_SECTION_LIST}
                type="mobileSection"
              />
            </div>
            <div className={cx(styles.mobileMiscMenuListItems)}>
              <HeaderMenuList
                testId="mobile-misc-links"
                items={MOBILE_MISC_LIST}
                type="mobileMisc"
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
