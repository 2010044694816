import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

type KeywordMetaDataProps = {
  tagName: string;
  tagAlias: string;
};

export default function KeywordMetaData({
  tagName,
  tagAlias,
}: KeywordMetaDataProps): PageMetaDataValues {
  const metaTitle = `Latest ${capitalize(tagName)} | Beritaharian`;
  const metaDescription = `${tagName} News - Find latest News & top stories about ${tagName}.`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: `tags/${tagAlias}`,
  };

  const gaData: GADataProps = {
    chapter1: tagName,
    level2: "keywords",
    title: `${tagName}_Index`,
    section: "keywords",
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
