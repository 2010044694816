import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import type { ArticleCardStyleProps } from "@elements/Card/Article/ArticleCard";
import ArticleCard from "@elements/Card/Article/ArticleCard";
import LoadMore from "@elements/LoadMore/LoadMore";
import { fetchMoreCampaignStoriesData } from "@pages/UGC/UGCListingPage/UGCListingPage.server";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@src/app/components/Elements/LoadMore/CreateLoadMoreHandler";
import useSetFirstRenderedArticleIds from "@src/app/components/Elements/LoadMore/SetFirstRenderedArticleIds";
import { displayAfter } from "@src/app/helper/utils";
import type { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import React, { useState } from "react";

const PAGE_SIZE = 8;
const INITIAL_ARTICLE_COUNT = 8;

type CampaignStoriesListProps = {
  campaignStories: ProcessedArticleData[];
  urlPath: string;
  sectionName: string;
};

export default function CampaignStoriesList({
  campaignStories,
  urlPath,
  sectionName,
}: CampaignStoriesListProps) {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean = campaignStories.length == INITIAL_ARTICLE_COUNT;

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(campaignStories, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? campaignStories.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: urlPath,
      sort: lastLoadedArticle?.sort,
    };
    const createLoadMoreHandlerProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreCampaignStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      lastLoadedArticle,
      loadMoreData,
      renderedArticleIds,
      pageSize: PAGE_SIZE,
      pageNumber,
    };

    const response = await CreateLoadMoreHandler(createLoadMoreHandlerProps);
    return response;
  };

  const overRideStyle: ArticleCardStyleProps = {
    cardWrapper: "flex flex-col gap-0",
    imageStyle: "w-full",
  };

  return (
    <>
      <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {[...campaignStories, ...loadMoreData].map((article, index) => {
            return (
              <React.Fragment key={index}>
                {displayAfter(index, PAGE_SIZE) && (
                  <div
                    data-testid={index}
                    className="col-span-1 md:col-span-3 lg:col-span-4"
                  >
                    <CompanionAdsLb
                      uniqueSectionName={sectionName}
                      index={index / PAGE_SIZE}
                      pageAdTargetType={PageAdTargetingTypeEnum.LISTING}
                    />
                  </div>
                )}
                <ArticleCard
                  displayWidth={218}
                  displayHeight={157}
                  overrideStyle={overRideStyle}
                  {...article}
                />
              </React.Fragment>
            );
          })}
          {showLoadMore ? (
            <LoadMore
              rootClassName="my-4"
              onLoadMore={handleLoadMore}
              hasMore={hasMoreStories}
              loadText="Lagi Cerita"
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
