import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import { URL_ORIGIN } from "@elements/MetaTags/MetaTagsConstants";
import { ProcessedArticleData } from "@transformer/useOSResponse";

import { removeLeadingSlash } from "./removeTrailingLeadingSlash";
import { formatDate } from "./useDateFormat";

export function getArticleJSONLD(article: ProcessedArticleData): object {
  const {
    urlPath,
    title,
    imageField,
    datetime,
    dateEdited,
    authors,
    contentAccess,
  } = article;
  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Article",
        headline: title,
        name: title,
        image: {
          "@type": "ImageObject",
          representativeOfPage: "True",
          url: imageField?.url,
        },
        datePublished: formatDate(datetime, "MMM d, yyyy | hh:mm a"),
        dateModified: formatDate(dateEdited, "MMM d, yyyy | hh:mm a"),
        hasPart: {
          "@type": "WebPageElement",
          cssSelector: ".paywall",
        },
        isAccessibleForFree:
          contentAccess !== LabelCardIconVariety.PREMIUM ? "True" : "False",
        author: {
          "@type": "Person",
          name: authors?.[0]?.name,
          url: `${URL_ORIGIN}${removeLeadingSlash(authors?.[0]?.urlPath || "")}`,
        },
        publisher: {
          "@type": "Organization",
          "@id": URL_ORIGIN,
          name: "BeritaHarian",
          url: URL_ORIGIN,
        },
        mainEntityOfPage: `${URL_ORIGIN}${removeLeadingSlash(urlPath || "")}`,
      },
    ],
  };

  return jsonLd;
}
