import { twMerge } from "tailwind-merge";

type QuizButtonProps = {
  handleClick: () => void;
  text: string;
  wrapperStyle?: string;
  buttonStyle?: string;
};

export default function QuizButton({
  handleClick,
  text,
  wrapperStyle = "",
  buttonStyle = "",
}: QuizButtonProps) {
  return (
    <div
      className={twMerge(
        "py-1 sm:py-2 px-3 text-sm cursor-pointer basis-3/12",
        wrapperStyle,
      )}
    >
      <button
        className={twMerge(
          "flex items-center justify-center text-center w-full",
          buttonStyle,
        )}
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  );
}
