import { GADataProps } from "@elements/GAData/GAData";
import {
  getAuthorsName,
  getChapter1,
  getContentCat,
  getLevel2,
  getTagsName,
} from "@elements/GAData/GADataHelper";
import { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { getArticleJSONLD } from "@helper/jsonLDHelper";
import {
  capitalize,
  replaceSpacesWithUnderscores,
} from "@helper/stringTransform";
import { dateFormatWithTz, formatDate } from "@helper/useDateFormat";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import {
  ProcessedArticleBody,
  ProcessedFullParagraph,
  RawImageField,
} from "@typings/Opensearch";
import { PageMetaDataValues } from "@typings/PageMetaData";

type ArticleMetaDataProps = {
  article: ProcessedArticleData;
  isMainArticle: boolean;
  gsChannels: string[];
};

export default function ArticleMetaData({
  article,
  isMainArticle,
  gsChannels,
}: ArticleMetaDataProps): PageMetaDataValues {
  const keywords = getTagsName(article.tags || []);
  const image = {
    url: article.imageField?.url,
    width: 480,
    height: 270,
  } as RawImageField;
  const level2 = getLevel2(article);

  const gaData = {
    title: replaceSpacesWithUnderscores(article.title),
    level2: level2,
    chapter1: getChapter1(article),
    contentcat: getContentCat(article.contentAccess),
    section: level2,
    contenttype: "1",
    pagination: 1,
    author: getAuthorsName(article.authors || []),
    cue_articleid: article.id,
    articleid: article.id,
    keyword: keywords,
    pubdate: formatDate(article.datetime, "yyyy-MM-dd HH:mm:ss"),
    content_publication_date_utc: article.datetime,
    content_update_date_utc: article.dateEdited,
    infinite_scroll: isMainArticle ? "0" : "1",
  } as GADataProps;

  const metaTags = {
    title: `${article.title}, Berita ${capitalize(level2)} - Beritaharian.sg`,
    description: `Berita - ${getFirstParagraph(article.articleBody)} Baca lebih lanjut di BeritaHarian.sg`,
    slug: article.urlPath,
    ogType: "Article",
    keywords: keywords.split(","),
    robotsContent: "notranslate",
    twitterCardContent: "summary",
    image: image,
    isArticlePage: true,
    publishedTime: formatDate(article.datetime, dateFormatWithTz),
    modifiedTime: formatDate(article.dateEdited, dateFormatWithTz),
    jsonLd: getArticleJSONLD(article),
    gsChannels,
  } as MetaTagsProps;

  return {
    gaData: gaData,
    metaTags: metaTags,
  };
}

function getFirstParagraph(articleBody: ProcessedArticleBody | null): string {
  if (!articleBody) return "";
  const para = articleBody.find(
    (element) => element.type === "paragraph",
  ) as ProcessedFullParagraph;
  return para.value;
}
