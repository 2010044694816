import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@src/app/types/Ads.d";
import { twMerge } from "tailwind-merge";

import { adSlotSizes, pubPageAdTargeting } from "../AdsConstants";
import CompanionAdvertisement from "../CompanionAdvertisement";

type AdsBottomSpecialProps = {
  uniqueSectionName?: string;
  rootClassName?: string;
  pageAdTargetType: PageAdTargetValue;
  index: number;
  gsChannels?: string[];
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 */

export function CompanionAdsBottomSpecial({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  index,
  gsChannels,
}: AdsBottomSpecialProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.BOTTOMSPECIAL,
        slot: GoogleAdsSlotFactory.bottomspecial(uniqueSectionName),
        id: `dfp-ad-bottomspecial-${index}`,
        adSlotSize: adSlotSizes["bottomspecial"],
        pubAdTargeting: [PubPageAdTarget],
        pageNumber: index.toString(),
        gsChannels,
      }}
    />
  );
}
